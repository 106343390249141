import { observable, action } from "mobx"
import Http from "../../helpers/http"
import { authApi } from "../../configs/api"

class MainProductsStore {
  @observable isLoading = false
  @observable isError = false
  @observable errorMsg = false
  @observable productsList = []
  @observable currencyList = []
  @observable count = null

  @action getProducts = async () => {
    try {
      this.isLoading = true
      this.errorMsg = false
      this.isError = false
      const response = await Http.get(
        `${authApi}/v1/merchant/products-published`,
        true
      )
      if (!response.message) {
        this.productsList = response.rows
        this.count = response.count
        if (response.count) {
          await this.getCurrencies([
            ...new Set(response.rows.map(({ currencyId }) => currencyId)),
          ])
        } else {
          this.isLoading = false
        }
      } else {
        this.isError = true
        this.errorMsg = response.message
      }
    } catch (e) {
      this.isError = true
      this.errorMsg = e
      this.isLoading = false
    }
  }
  @action getCurrencies = async (currencies) => {
    try {
      this.isLoading = true
      this.errorMsg = false
      this.isError = false
      const response = await Promise.all(
        currencies.map((currencyId) =>
          Http.get(`${authApi}/v1/currency/get/${currencyId}`, true)
        )
      )
      if (!response.find((response) => Boolean(response.message))) {
        this.currencyList = response
      } else {
        this.isError = true
        this.errorMsg = response.message
      }
      this.isLoading = false
    } catch (e) {
      this.isError = true
      this.errorMsg = e
      this.isLoading = false
    }
  }
}

export default new MainProductsStore()
