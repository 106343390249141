import { observable, action } from "mobx"
import Http from "../../../helpers/http"
import { authApi } from "../../../configs/api"

class MerchantMessagesStore {
  @observable isError = false
  @observable errorMsg = false
  @observable isLoading = false
  @observable messages = []
  @observable count = 0

  @action getMessages = async (id) => {
    try {
      this.errorMsg = false
      this.isError = false
      this.isLoading = true
      const response = await Http.get(
        `${authApi}/v1/merchant/messages/get/${id}`,
        true
      )
      this.isLoading = false
      if (!response.message) {
        this.messages = response.rows
        this.count = response.count
      } else {
        this.isError = true
        this.errorMsg = response.message
      }
    } catch (e) {
      this.isLoading = false
      this.isError = true
      this.errorMsg = e
    }
  }
  @action getMessagesInterval = async (id) => {
    try {
      this.errorMsg = false
      this.isError = false
      const response = await Http.get(
        `${authApi}/v1/merchant/messages/get/${id}`,
        true
      )
      if (!response.message) {
        this.messages = response.rows
        this.count = response.count
      } else {
        this.isError = true
        this.errorMsg = response.message
      }
    } catch (e) {
      this.isError = true
      this.errorMsg = e
    }
  }
  @action setMessageNotActual = async (messageId, merchantId) => {
    try {
      await Http.post(
        `${authApi}/v1/merchant/messages/not-actual`,
        { messageId, merchantId },
        true
      )
      this.messages = this.messages.filter(({ id }) => id !== messageId)
      this.count = this.messages.length
      if (this.count === 0) {
        return true
      }
    } catch (e) {
      this.isError = true
      this.errorMsg = e
    }
  }
}

export default new MerchantMessagesStore()
