import { observable, action } from "mobx"
import Http from "../../../helpers/http"
import { authApi } from "../../../configs/api"

class MerchantUsersStore {
  @observable isLoading = false
  @observable isError = false
  @observable errorMsg = false
  @observable usersList = []
  @observable selectedUserId = null

  @action getUsers = async (id) => {
    try {
      this.isLoading = true
      this.errorMsg = false
      this.isError = false
      const response = await Http.get(
        `${authApi}/v1/merchant/user/get-users/${id}`,
        true
      )
      this.isLoading = false
      if (!response.message) {
        this.usersList = response.rows
      } else {
        this.isError = true
        this.errorMsg = response.message
      }
    } catch (e) {
      this.isError = true
      this.errorMsg = e
      this.isLoading = false
    }
  }

  newUser = (newUser) => {
    this.usersList = [...this.usersList, newUser]
  }
  @action setSelectedUser = (id) => {
    this.selectedUser = id
  }
}

export default new MerchantUsersStore()
