import React, { useRef, useState } from "react"
import { withRouter } from "react-router"
import { inject, observer } from "mobx-react"
import { Field, Form, Formik } from "formik"
import { InputTextarea } from "primereact/inputtextarea"
import { Message } from "primereact/message"
import { Messages } from "primereact/messages"
import { Button } from "primereact/button"
import { FileUpload } from "primereact/fileupload"
import { OverlayPanel } from "primereact/overlaypanel"

import * as yup from "yup"

import CustomInput from "../../common/CustomInput"
import CustomSelect from "../../common/CustomSelect"
import Preloader from "../../Preloader"
import { ErrorMsg } from "../../../constants/errorMsg"
import {
  PRODUCT_NAME_MIN_LENGTH,
  PRODUCT_NAME_MAX_LENGTH,
  PRODUCT_DESCRIPTION_MIN_LENGTH,
  PRODUCT_DESCRIPTION_MAX_LENGTH,
  PRICE_REGEXP,
} from "../../../constants/merchant"
import { getMessage } from "../../../services/getMessage"

const AddProduct = observer(
  ({
    match,
    creatingProduct,
    isLoading,
    msg,
    errorMsg,
    image,
    availableCurrencies,
    addProduct,
    setImage,
    clearImage,
    clearMessages,
  }) => {
    let panelRef = useRef(null)
    let uploadRef = useRef(null)
    let infoMessage = useRef(null)
    const [imageError, setImageError] = useState("")
    const merchantId = Number(match.params.id)

    const schema = yup.object().shape({
      name: yup
        .string()
        .required(ErrorMsg.PRODUCT_NAME_REQUIRED)
        .min(PRODUCT_NAME_MIN_LENGTH, ErrorMsg.PRODUCT_NAME_NOT_VALID)
        .max(PRODUCT_NAME_MAX_LENGTH, ErrorMsg.PRODUCT_NAME_NOT_VALID),
      description: yup
        .string()
        .required(ErrorMsg.PRODUCT_DESCRIPTION_REQUIRED)
        .min(
          PRODUCT_DESCRIPTION_MIN_LENGTH,
          ErrorMsg.PRODUCT_DESCRIPTION_NOT_VALID
        )
        .max(
          PRODUCT_DESCRIPTION_MAX_LENGTH,
          ErrorMsg.PRODUCT_DESCRIPTION_NOT_VALID
        ),
      price: yup
        .string()
        .required(ErrorMsg.PRODUCT_PRICE_REQUIRED)
        .matches(PRICE_REGEXP, ErrorMsg.PRODUCT_PRICE_NOT_VALID),
      currencyId: yup
        .number()
        .positive(ErrorMsg.CURRENCY_ID_REQUIRED)
        .required(ErrorMsg.CURRENCY_ID_REQUIRED)
        .typeError(ErrorMsg.CURRENCY_ID_REQUIRED),
      productType: yup
        .number()
        .min(0, ErrorMsg.PRODUCT_TYPE_REQUIRED)
        .required(ErrorMsg.PRODUCT_TYPE_REQUIRED)
        .typeError(ErrorMsg.PRODUCT_TYPE_REQUIRED),
    })

    const renderImage = image ? (
      <div className="p-col-12 ">
        <img style={{ maxWidth: "15%" }} src={image.objectURL} alt="product" />
      </div>
    ) : null

    const renderFileUpload = (
      <FileUpload
        ref={uploadRef}
        className="p-button button-auto-width"
        name="demo"
        mode="basic"
        accept=".gif, .jpg, .png"
        customUpload={true}
        chooseLabel={image ? "Change picture" : "Select picture"}
        auto={true}
        uploadHandler={({ files }) => {
          const regExpFileType = new RegExp(/^.*\.(jpe?g|png|gif)$/i)
          uploadRef.current.clear()
          setImageError("")
          if (files[0].size > 5242880) {
            setImageError("Maximum upload size is 5mb")
            return
          }
          if (!regExpFileType.test(files[0].name)) {
            setImageError("Only *.jpeg, *.png and *.gif files supported")
            return
          }
          setImage(files[0])
        }}
      />
    )

    const renderClearButton = image ? (
      <Button
        label="Clear Image"
        icon="pi pi-times"
        iconPos="left"
        className="p-button button-auto-width"
        onClick={(e) => {
          e.stopPropagation()
          e.nativeEvent.stopImmediatePropagation()
          clearImage()
        }}
      />
    ) : null

    const TextArea = ({ form, field }) => {
      const showError = Boolean(
        form.touched[field.name] && form.errors[field.name]
      )
      return (
        <>
          <div className="p-float-label">
            <InputTextarea
              id="float-textarea"
              rows={4}
              style={{ width: "100%" }}
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              autoResize={true}
            />
            <label htmlFor="float-textarea">Describe your product here</label>
          </div>
          {showError && (
            <Message
              severity="error"
              text={getMessage(form.errors[field.name])}
            />
          )}
        </>
      )
    }
    if (msg) {
      infoMessage.current.show([
        { severity: "info", detail: msg, closable: false },
      ])
      clearMessages()
    }

    if (errorMsg) {
      infoMessage.current.show([
        { severity: "error", detail: errorMsg, closable: false },
      ])
      clearMessages()
    }

    return (
      <>
        <div className="my-4">
          <Button
            className="mx-0.5 p-button button-auto-width"
            type="button"
            label="Add Product"
            icon="pi pi-plus"
            iconPos="right"
            onClick={(e) => panelRef.current.toggle(e)}
          />
        </div>
        <OverlayPanel
          dismissable={false}
          showCloseIcon={true}
          ref={panelRef}
          className="mx-2 p-col-8"
        >
          {isLoading || creatingProduct ? (
            Preloader(
              isLoading
                ? "Loading available currencies ..."
                : "Creating new product ..."
            )
          ) : (
            <Formik
              initialValues={{
                name: "",
                description: "",
                price: 0,
                currencyId: 0,
                productType: -1,
              }}
              enableReinitialize={true}
              validationSchema={schema}
              onSubmit={(values) => {
                values.price = Math.trunc(values.price * 100)
                addProduct(values, merchantId)
              }}
            >
              {({ dirty }) => (
                <Form>
                  <div className="p-grid pt-3">
                    <div className="p-col-12">
                      <Messages ref={infoMessage} />
                    </div>
                    <div className="p-col-12 ">
                      <Field
                        type="text"
                        name="name"
                        labelText="Product name"
                        component={CustomInput}
                        className="p-col-12"
                      />
                    </div>
                    <div className="p-col-12">
                      <Field
                        type="text"
                        name="description"
                        labelText="Product description"
                        component={TextArea}
                      />
                    </div>
                    <div className="p-col-12">
                      <Field
                        step="0.01"
                        type="number"
                        name="price"
                        labelText="Product price"
                        component={CustomInput}
                      />
                    </div>
                    <div className="p-col-6">
                      <Field
                        type="text"
                        className="p-col-12 py-0 mb-2"
                        name={"currencyId"}
                        options={availableCurrencies.map(({ name, id }) => ({
                          label: name,
                          value: id,
                        }))}
                        labelText="Select a Currency"
                        component={CustomSelect}
                      />
                    </div>
                    <div className="p-col-6">
                      <Field
                        type="text"
                        className="p-col-12 py-0 mb-2"
                        name={"productType"}
                        options={[
                          { label: "Service", value: 0 },
                          { label: "Physical product", value: 1 },
                          { label: "E-product", value: 2 },
                        ]}
                        labelText="Select product type"
                        component={CustomSelect}
                      />
                    </div>
                  </div>

                  {renderImage}
                  <div className="p-grid p-align-start px-2 py-2">
                    {renderFileUpload}
                    {renderClearButton}
                  </div>
                  {imageError ? (
                    <>
                      <div class="p-col-12 px-0 py-0" />
                      <div
                        aria-live="polite"
                        class="p-col-6 p-message p-component p-message-error text-center my-2"
                        role="alert"
                      >
                        <span class="p-message-text">{imageError}</span>
                      </div>
                    </>
                  ) : (
                    <p class="p-col-12 mt-0 mb-3">
                      Only *.jpeg, *.png and *.gif files will be accepted,size
                      should be no more than 5mb.
                    </p>
                  )}
                  <div className="p-col-12 px-0">
                    <Button
                      type="submit"
                      label="Add Product"
                      disabled={!(dirty && !isLoading)}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </OverlayPanel>
      </>
    )
  }
)

const withData = inject((stores) => ({
  creatingProduct: stores.MerchantAddProductStore.creatingProduct,
  isLoading: stores.MerchantProductsStore.isLoading,
  msg: stores.MerchantAddProductStore.msg,
  errorMsg: stores.MerchantAddProductStore.errorMsg,
  image: stores.MerchantAddProductStore.image,
  availableCurrencies: stores.MerchantProductsStore.availableCurrencies,
  setImage: stores.MerchantAddProductStore.setImage,
  clearImage: stores.MerchantAddProductStore.clearImage,
  addProduct: stores.MerchantAddProductStore.addProduct,
  clearMessages: stores.MerchantAddProductStore.clearMessages,
}))

export default withData(withRouter(AddProduct))
