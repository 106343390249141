import { action, observable } from "mobx"
import Http from "../../helpers/http"
import { authApi } from "../../configs/api"
import { getMessage } from "../../services/getMessage"

class ChangePasswordStore {
  @observable isLoading = false
  @observable isError = false
  @observable errorMsg = null

  @action
  changePassword = async (data, { resetForm }) => {
    try {
      this.isLoading = true
      const response = await Http.post(
        `${authApi}/v1/user/password`,
        data,
        true
      )
      if (response && response.message) {
        this.isLoading = false
        this.isError = true
        this.errorMsg = getMessage(response.message)
      } else {
        this.isLoading = false
        this.isError = false
        this.errorMsg = null
        resetForm()
      }
    } catch (e) {
      this.isLoading = false
      this.isError = true
      this.errorMsg = e
    }
  }
}

export default new ChangePasswordStore()
