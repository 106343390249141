import React, { useEffect } from "react"
import { inject, observer } from "mobx-react"
import { Field, Form, Formik } from "formik"
import * as yup from "yup"
import { Button } from "primereact/button"
import { Message } from "primereact/message"
import CustomInput from "../../../components/common/CustomInput"
import CustomInputMask from "../../../components/common/CustomInputMask"
import { ErrorMsg } from "../../../constants/errorMsg"
import { MAX_USER_NAME_LENGTH, MIN_USER_NAME_LENGTH, PHONE_REGEXP, USER_NAME_REGEXP } from "../../../constants/user"

const schema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required(ErrorMsg.FIRST_NAME_REQUIRED)
    .min(MIN_USER_NAME_LENGTH, ErrorMsg.FIRST_NAME_NOT_VALID)
    .max(MAX_USER_NAME_LENGTH, ErrorMsg.FIRST_NAME_NOT_VALID)
    .matches(USER_NAME_REGEXP, ErrorMsg.FIRST_NAME_NOT_VALID),
  lastName: yup
    .string()
    .trim()
    .required(ErrorMsg.LAST_NAME_REQUIRED)
    .min(MIN_USER_NAME_LENGTH, ErrorMsg.LAST_NAME_NOT_VALID)
    .max(MAX_USER_NAME_LENGTH, ErrorMsg.LAST_NAME_NOT_VALID)
    .matches(USER_NAME_REGEXP, ErrorMsg.LAST_NAME_NOT_VALID),
  email: yup
    .string()
    .trim()
    .email(ErrorMsg.EMAIL_NOT_VALID)
    .required(ErrorMsg.EMAIL_REQUIRED),
  phone: yup
    .string()
    .trim()
    .required(ErrorMsg.PHONE_REQUIRED)
    .matches(PHONE_REGEXP, ErrorMsg.PHONE_NOT_VALID),
})

const PersonalInfo = observer(({ userInfo, isLoading, updateUserInfo, isError, errorMsg, clearErrors }) => {
  const inputList = [
    { value: "firstName", label: "First Name" },
    { value: "lastName", label: "Last Name" },
    { value: "email", label: "Email" },
  ]

  useEffect(() => {
    return () => {
      clearErrors()
    }
  }, [clearErrors])

  const inputListRender = inputList.map((input, i) => (
    <div key={i} className="p-col-12 p-md-6">
      <Field
        type="text"
        name={input.value}
        labelText={input.label}
        component={CustomInput}
      />
    </div>
  ))

  return (
    <Formik
      initialValues={{
        id: userInfo.id || null,
        firstName: userInfo.firstName || "",
        lastName: userInfo.lastName || "",
        email: userInfo.email || "",
        phone: userInfo.phone || "",
      }}
      enableReinitialize
      validationSchema={schema}
      onSubmit={updateUserInfo}
    >
      {({ dirty }) => (
        <Form>
          <div className="p-grid">
            {isError && errorMsg && (
              <div className="p-col-12">
                <Message severity="error" text={errorMsg}/>
              </div>
            )}
            {inputListRender}
            <div className="p-col-12 p-md-6">
              <Field
                type="text"
                name="phone"
                labelText="Phone Number"
                mask="+(999)-999-999999"
                component={CustomInputMask}
              />
            </div>
            <div className="p-col-12">
              <Button
                type="submit"
                label="Update"
                disabled={!(dirty && !isLoading)}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
})

const withData = inject((stores) => ({
  isLoading: stores.AuthStore.isLoading,
  userInfo: stores.AuthStore.user,
  updateUserInfo: stores.AuthStore.updateUserInfo,
  isError: stores.AuthStore.isError,
  errorMsg: stores.AuthStore.errorMsg,
  clearErrors: stores.AuthStore.clearErrors,
}))

export default withData(PersonalInfo)
