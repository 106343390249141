import React, { useEffect } from "react"
import { inject, observer } from "mobx-react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import CardSetupForm from "./CardSetupForm"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {locale: "en"})

const StripeAddCard = observer(({ createCardIntent, stripeSecret }) => {

    useEffect(() => {
      createCardIntent()
    }, [createCardIntent])

    return (
      <Elements stripe={stripePromise}>
        {stripeSecret && (
          <CardSetupForm/>
        )}
      </Elements>
    )
  }
)

const withData = inject((stores) => ({
  createCardIntent: stores.StripeStore.createCardIntent,
  stripeSecret: stores.StripeStore.stripeSecret,
}))

export default withData(StripeAddCard)
