import { observable, action } from "mobx"
import Http from "../../../helpers/http"
import { authApi } from "../../../configs/api"
import MerchantProductsStore from "../ProductsList/mobx"

const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onerror = reject

    reader.readAsArrayBuffer(file)
  })
}

const arrayBufferToBase64 = (buffer) => {
  let binary = ""
  let bytes = new Uint8Array(buffer)
  let len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

const fileToBase64 = async (file) => {
  const fileBuffer = await readFileAsync(file)
  const fileBase64 = arrayBufferToBase64(fileBuffer)
  return { file: fileBase64, filename: file.name }
}

class MerchantAddProductStore {
  @observable isError = false
  @observable errorMsg = false
  @observable creatingProduct = false
  @observable image = null
  @observable msg

  @action setImage = (image) => {
    this.image = image
  }
  @action clearImage = () => {
    this.image = null
  }

  @action clearMessages = () => {
    this.errorMsg = null
    this.msg = null
  }

  @action addProduct = async (productInfo, merchantId) => {
    try {
      this.errorMsg = false
      this.isError = false
      this.creatingProduct = true
      let imgUpload
      if (this.image) {
        imgUpload = await Http.post(
          `${authApi}/v1/img/upload`,
          await fileToBase64(this.image),
          true
        )
      }
      const response = await Http.post(
        `${authApi}/v1/merchant/product/add`,
        { ...productInfo, merchantId, image: imgUpload && imgUpload.filename },
        true
      )
      this.creatingProduct = false
      if (response.message) {
        this.isError = true
        this.errorMsg = response.message
      } else {
        this.setImage(null)
        this.msg = "New Product created"
        MerchantProductsStore.newProduct(response)
      }
    } catch (e) {
      this.creatingProduct = false
      this.isError = true
      this.errorMsg = e
    }
  }
}

export default new MerchantAddProductStore()
