import React, { useEffect, useState, useRef } from "react"
import { observer, inject } from "mobx-react"
import { Redirect } from "react-router-dom"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import FilterForm from "./components/FilterForm"
import moment from "moment"

const MerchantsList = observer(
  ({ isLoading, merchantsList, count, getMerchants, setSelectedMerchant }) => {
    const dt = useRef(null)

    const [limit, setLimit] = useState(50)
    const [offset, setOffset] = useState(0)
    const [from, setFrom] = useState(null)
    const [to, setTo] = useState(null)
    const [redirectURL, setRedirectURL] = useState("")
    const [redirect, setRedirect] = useState(false)

    const onPage = (event) => {
      setOffset(event.first)
      setLimit(event.rows)
    }

    useEffect(() => {
      getMerchants(limit, offset, from, to)
    }, [getMerchants, limit, offset, from, to])

    const cols = [
      { field: "name", header: "Company Name" },
      { field: "phone", header: "Phone" },
      { field: "address", header: "Adress" },
      { field: "email", header: "Email" },
      { field: "regNumber", header: "Registration Number" },
      { field: "taxId", header: "Tax ID" },
    ]

    const dynamicColumns = cols.map((col) => {
      return <Column key={col.field} field={col.field} header={col.header} />
    })
    const formattedDate = (rowData) => {
      return <>{moment(rowData.createdAt).format("DD/MM/YYYY HH:mm:ss")}</>
    }

    const onRowClick = (e) => {
      setSelectedMerchant(e.data.id)
      setRedirectURL("/merchant/" + e.data.id)
      setRedirect(true)
    }
    return (
      <>
        <div className="p-fluid p-grid mt-3">
          {redirect ? (
            <Redirect to={redirectURL} />
          ) : (
            <>
              <div className="p-col-12">
                <FilterForm
                  limit={limit}
                  offset={offset}
                  setTo={setTo}
                  setFrom={setFrom}
                />
              </div>
              <div className="p-col-12">
                <DataTable
                  ref={dt}
                  dataKey="id"
                  value={merchantsList}
                  rowHover
                  paginator
                  first={offset}
                  rows={limit}
                  onRowClick={onRowClick}
                  emptyMessage="Merchants not found"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} merchants"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  rowsPerPageOptions={[50, 100, 200]}
                  loading={isLoading}
                  lazy={true}
                  totalRecords={count}
                  onPage={onPage}
                >
                  {dynamicColumns}
                  <Column
                    key="createdAt"
                    field="createdAt"
                    header="Date"
                    body={formattedDate}
                  />
                </DataTable>
              </div>
            </>
          )}
        </div>
      </>
    )
  }
)

const withData = inject((stores) => ({
  isLoading: stores.MerchantsStore.isLoading,
  merchantsList: stores.MerchantsStore.merchantsList,
  count: stores.MerchantsStore.count,
  getMerchants: stores.MerchantsStore.getMerchants,
  setSelectedMerchant: stores.MerchantsStore.setSelectedMerchant,
}))

export default withData(MerchantsList)
