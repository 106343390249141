import React from "react"
import { Card } from "primereact/card"
import PersonalInfo from "./components/PersonalInfo"
import ChangePassword from "./components/ChangePassword"
import PersonalSettings from "./components/PersonalSettings"

const UserInfo = () => (
  <div className="p-fluid p-grid">
    <div className="p-col-12 p-lg-6">
      <Card title="Personal Info">
        <PersonalInfo/>
      </Card>
    </div>
    <div className="p-col-12 p-lg-6">
      <Card title="Change your password">
        <ChangePassword/>
      </Card>
    </div>
    <div className="p-col-12 p-lg-6">
      <Card title="Personal Settings">
        <PersonalSettings/>
      </Card>
    </div>
  </div>
)

export default UserInfo
