import React from 'react'
import { InputMask } from 'primereact/inputmask'
import { Message } from 'primereact/message'
import { getMessage } from '../../../services/getMessage'

const CustomInputMask = ({ form, labelText, field, mask }) => {
  const showError = !!(form.touched[field.name] && form.errors[field.name])
  return (
    <>
      <span className="p-float-label my-2">
        <InputMask
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          className={showError && 'p-error' || ''}
          mask={mask}
          autoClear={false}
          unmask
        />
        <label htmlFor={field.name}>{labelText}</label>
      </span>
      {showError && (
        <Message severity="error" text={getMessage(form.errors[field.name])}/>
      )}
    </>
  )
}

export default CustomInputMask
