import React from "react"
import { observer } from "mobx-react"

import Wallets from "../../components/Wallets"

const WalletsPage = observer(() => (
  <Wallets />
))

export default WalletsPage
