import React from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import PrivateRoute from "./PrivateRoute"
import LoginPage from "./pages/LoginPage"
import MainPage from "./pages/MainPage"
import CreateMerchant from "./pages/CreateMerchant"
import MerchantsList from "./pages/MerchantsList"
import Merchant from "./pages/Merchant"
import UserInfo from "./pages/UserInfo"
import ForgotPassword from "./pages/ForgotPassword"
import ChangePasswordByToken from "./pages/ChangePasswordByToken"
import WalletsPage from "./pages/WalletsPage"
import CreditCardsPage from "./pages/CreditCardsPage"
import WalletInfoPage from "./pages/WalletInfoPage"

import "./assets/styles/index.scss"

const App = () => {
  const test = "test msg"
  console.log(test)

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={LoginPage} exact />
        <Route path="/forgot-password" component={ForgotPassword} exact />
        <Route
          path="/change-password/:token"
          component={ChangePasswordByToken}
          exact
        />
        <PrivateRoute
          exact
          path="/dashboard"
          component={MainPage}
          title="Dashboard"
        />
        <PrivateRoute
          exact
          path="/create-merchant"
          component={CreateMerchant}
          title="Create Merchant"
        />
        <PrivateRoute
          exact
          path="/merchants"
          component={MerchantsList}
          title="My merchants"
        />
        <PrivateRoute
          exact
          path="/merchant/:id"
          component={Merchant}
          title="Merchant"
        />
        <PrivateRoute
          exact
          path="/profile"
          component={UserInfo}
          title="Profile"
        />
        <PrivateRoute
          exact
          path="/wallets/:id"
          component={WalletInfoPage}
          title="Wallet"
        />
        <PrivateRoute
          exact
          path="/wallets"
          component={WalletsPage}
          title="Wallets"
        />
        <PrivateRoute
          exact
          path="/cards"
          component={CreditCardsPage}
          title="Credit cards"
        />
      </Switch>
    </BrowserRouter>
  )
}

export default App
