export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 50;
export const PASSWORD_REGEXP = /^(?=.*\d).{8,50}$/;

export const MIN_USER_NAME_LENGTH = 2;
export const MAX_USER_NAME_LENGTH = 32;
export const USER_NAME_REGEXP = /^[a-zA-Z][a-zA-Z- ]*[a-zA-Z]$/;

export const PHONE_REGEXP = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

