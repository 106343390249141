import React, { useState } from "react"
import { inject, observer } from "mobx-react"
import { Button } from "primereact/button"
import { Message } from "primereact/message"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import CardSection from "./CardSection"

const CardSetupForm = observer(({ stripeSecret, userInfo, getCreditCards }) => {
  const [errorMsg, setErrorMsg] = useState(null)
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event) => {
    event.preventDefault()
    const cardElement = elements.getElement(CardElement)

    if (!stripe || !elements) {
      return
    }

    const result = await stripe.confirmCardSetup(stripeSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          email: userInfo.email,
        },
      }
    })

    if (result.error) {
      setErrorMsg(result.error.message)
    } else {
      cardElement.clear()
      getCreditCards()
      setErrorMsg(null)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      {errorMsg && (
        <div className="p-col-12">
          <Message severity="error" text={errorMsg}/>
        </div>
      )}
      <div className="p-col-12">
        <CardSection/>
      </div>
      <div className="p-col-12 mt-4">
        <Button
          label={"Save Card"}
          disabled={!stripeSecret || !stripe}
        />
      </div>
    </form>
  )
})

const withData = inject((stores) => ({
  getCreditCards: stores.StripeStore.getCreditCards,
  stripeSecret: stores.StripeStore.stripeSecret,
  userInfo: stores.AuthStore.user,
}))

export default withData(CardSetupForm)
