import React from "react"
import { inject, observer } from "mobx-react"
import moment from "moment"
import { Button } from "primereact/button"
import { Field, Form, Formik } from "formik"
import CustomCalendar from "../../../components/common/CustomCalendar"
import { withRouter } from "react-router"

const FilterForm = observer(
  ({ limit, offset, isLoading, setFrom, setTo, getMerchants }) => {
    const useFilters = (values) => {
      let from = null
      let to = null
      if (values.dateRange) {
        const dateFrom = new Date(values.dateRange[0])
        dateFrom.setUTCHours(0)
        dateFrom.setUTCMinutes(0)
        dateFrom.setUTCSeconds(0)
        dateFrom.setDate(dateFrom.getDate() + 1)
        from = moment(dateFrom).utc().format()

        const dateTo = new Date(values.dateRange[1])
        dateTo.setUTCHours(23)
        dateTo.setUTCMinutes(59)
        dateTo.setUTCSeconds(59)
        dateTo.getDate(59)
        dateTo.setDate(dateTo.getDate() + 1)
        to = moment(dateTo).utc().format()
      }
      setFrom(from)
      setTo(to)
      getMerchants(limit, offset, from, to)
    }

    return (
      <Formik
        initialValues={{
          dateRange: null,
        }}
        enableReinitialize
        onSubmit={useFilters}
      >
        {({ resetForm }) => (
          <Form>
            <div className="p-grid">
              <div className="p-col-12 p-md-3">
                <Field
                  name="dateRange"
                  labelText="Date"
                  component={CustomCalendar}
                />
              </div>
              <div className="p-col-12 p-md-3">
                <div className="p-grid">
                  <div className="p-col">
                    <Button
                      className="my-2"
                      type="submit"
                      label="Filter"
                      disabled={isLoading}
                    />
                  </div>
                  <div className="p-col">
                    <Button
                      className="p-button-secondary my-2"
                      type="button"
                      label="Clear"
                      onClick={resetForm}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    )
  }
)

const withData = inject((stores) => ({
  isLoading: stores.MerchantsStore.isLoading,
  getMerchants: stores.MerchantsStore.getMerchants,
}))

export default withData(withRouter(FilterForm))
