import React from "react"
import { withRouter } from "react-router-dom"
import { inject } from "mobx-react"
import { Redirect } from "react-router-dom"
import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav"
import { AiFillShopping } from "react-icons/ai"
import { GiWallet } from "react-icons/gi"
import { MdDashboard, MdExitToApp } from "react-icons/md"
import { FaShoppingBag, FaCcMastercard, FaUserFriends } from "react-icons/fa"

import "@trendmicro/react-sidenav/dist/react-sidenav.css"
import "./DrawerMenu.scss"

const DrawerMenu = (props) => {
  const { onClose, status, isLogin, logout } = props

  if (!isLogin) {
    return <Redirect to="/" />
  }

  return (
    <SideNav
      expanded={status}
      onToggle={onClose}
      onSelect={(selected) => {
        if (selected === "logout") {
          logout()
        } else {
          const to = "/" + selected
          if (props.location.pathname !== to) {
            props.history.push(to)
          }
        }
      }}
      style={{ width: status ? "250px" : "65px" }}
    >
      <Toggle />
      <Nav>
        <NavItem eventKey="dashboard">
          <NavIcon>
            <MdDashboard />
          </NavIcon>
          <NavText>Dashboard</NavText>
        </NavItem>
        <NavItem eventKey="profile">
          <NavIcon>
            <FaUserFriends />
          </NavIcon>
          <NavText>Profile</NavText>
        </NavItem>
        <NavItem eventKey="wallets">
          <NavIcon className="nav-icon">
            <GiWallet />
          </NavIcon>
          <NavText>Wallets</NavText>
        </NavItem>
        <NavItem eventKey="Cards">
          <NavIcon>
            <FaCcMastercard />
          </NavIcon>
          <NavText>Credit cards</NavText>
        </NavItem>
        <NavItem eventKey="create-merchant" className="mobile-hidden">
          <NavIcon>
            <AiFillShopping />
          </NavIcon>
          <NavText>Create merchant</NavText>
        </NavItem>
        <NavItem eventKey="merchants" className="mobile-hidden">
          <NavIcon>
            <FaShoppingBag />
          </NavIcon>
          <NavText>Merchants</NavText>
        </NavItem>
        <NavItem eventKey="logout">
          <NavIcon>
            <MdExitToApp />
          </NavIcon>
          <NavText>Logout</NavText>
        </NavItem>
      </Nav>
    </SideNav>
  )
}

const withData = inject((stores) => ({
  isLogin: stores.AuthStore.isLogin,
  logout: stores.AuthStore.logout,
}))

export default withRouter(withData(DrawerMenu))
