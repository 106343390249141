import React, { useEffect } from "react"
import { inject, observer } from "mobx-react"
import Frame from "react-frame-component"

const LiqPay = observer(
  ({ wallet, currency, amount, getCardForm, cardForm }) => {
    useEffect(() => {
      getCardForm(wallet, currency, amount)
    }, [getCardForm, wallet, currency, amount])

    const submitCardForm = () => {
      document
        .getElementById("card-iframe")
        .contentWindow.document.getElementById("liqpay-payment-frame")
        .firstElementChild.submit()
    }

    return (
      <>
        {cardForm !== "" ? (
          <Frame
            contentDidMount={submitCardForm}
            id="card-iframe"
            width="500"
            height="500"
            style={{ border: "none" }}
          >
            <span dangerouslySetInnerHTML={{ __html: cardForm }} />
          </Frame>
        ) : null}
      </>
    )
  }
)

const withData = inject((stores) => ({
  getCardForm: stores.LiqPayStore.getCardForm,
  cardForm: stores.LiqPayStore.cardForm,
}))

export default withData(LiqPay)
