import React from "react"
import { inject, observer } from "mobx-react"
import { Field, Form, Formik } from "formik"
import * as yup from "yup"

import CustomInput from "../../common/CustomInput"
import CustomSelect from "../../common/CustomSelect"
import { Button } from "primereact/button"
import { Card } from "primereact/card"

import { ErrorMsg } from "../../../constants/errorMsg"
import {
  MAX_WALLET_NAME_LENGTH,
  MIN_WALLET_NAME_LENGTH,
  WALLET_NAME_REGEXP,
} from '../../../constants/wallets'

const schema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(ErrorMsg.WALLETS_NAME_REQUIRED)
    .min(MIN_WALLET_NAME_LENGTH, ErrorMsg.WALLETS_NAME_MIN_VALID)
    .max(MAX_WALLET_NAME_LENGTH, ErrorMsg.WALLETS_NAME_MAX_VALID)
    .matches(WALLET_NAME_REGEXP, ErrorMsg.WALLETS_NAME_NOT_VALID),
  currencyCode: yup
    .string()
    .trim()
    .nullable()
    .required(ErrorMsg.WALLETS_CURRENCY_REQUIRED),
})

const WalletsForm = observer(
  ({ isMerchant, currencyList, createWallet, createMerchantWallet }) => {

    const pushNewWallet = walletInfo => {
      if (isMerchant) {
        createMerchantWallet({
          ...walletInfo,
          merchantId: Number(isMerchant),
        })
      } else {
        createWallet(walletInfo)
      }
    }

    return (
      <Card title="Create new wallet">

        <Formik
          initialValues={{
            name: '',
            currencyCode: '',
          }}
          enableReinitialize
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            await pushNewWallet(values)
            resetForm({})
          }}
        >
          {({ dirty }) => (
            <Form>
              <div className="p-grid p-dir-col create-wallet-form">
                <div className="p-col-12 wallet-field">
                  <Field
                    type="text"
                    name="name"
                    labelText="Name"
                    className="p-col-12"
                    component={CustomInput}
                  />
                </div>
                <div className="p-col-12 wallet-field">
                  <Field
                    name="currencyCode"
                    className="p-col-12 py-0"
                    options={currencyList.map(({ name, id }) => ({
                      label: name,
                      value: id,
                    }))}
                    labelText="Select a Currency"
                    component={CustomSelect}
                  />
                </div>
                <div className="p-col-12">
                  <Button
                    type="submit"
                    label="Create wallet"
                    disabled={!(dirty)}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    )
  }
)

const withData = inject((store) => ({
  currencyList: store.WalletsStore.currencyList,
  createWallet: store.WalletsStore.createWallet,
  createMerchantWallet: store.WalletsStore.createMerchantWallet,
}))

export default withData(WalletsForm)
