import React, { useState, useEffect } from "react"
import { inject, observer } from "mobx-react"

import { Dropdown } from "primereact/dropdown"
import { Button } from "primereact/button"

const WayForPayBalance = observer(
  ({
    creditCardsList,
    getCreditCards,
    refillWallet,
    wallet,
    amount,
    currency,
  }) => {
    const [creditCard, setCreditCard] = useState(null)
    const [recToken, setRecToken] = useState("")

    // const chgAmount = (e) => {
    //   if (
    //     /^(?=.*\d)\d*(?:\.\d{0,2})?$/.test(e.target.value) ||
    //     e.target.value === ""
    //   ) {
    //     setAmount(e.target.value)
    //   }
    //   if (e.target.value >= 1 && recToken.length > 0 && wallet) {
    //     setIsDisabled(false)
    //   } else {
    //     if (!isDisabled) {
    //       setIsDisabled(true)
    //     }
    //   }
    // }

    const tryRefillWallet = () => {
      refillWallet(
        wallet,
        amount,
        recToken,
        `Add to wallet ${wallet}`,
        currency
      )
    }

    useEffect(() => {
      getCreditCards()
    }, [getCreditCards])

    return (
      <>
        <div className="wallets-form__row">
          {creditCardsList.length !== 0 ? (
            <Dropdown
              value={creditCard}
              options={creditCardsList}
              optionLabel="cardPan"
              editable={false}
              onChange={(e) => {
                setCreditCard(e.value)
                setRecToken(e.value.recToken)
              }}
              placeholder="Select a credit card"
              className="wallets-form__dropdown "
            />
          ) : (
            <p>
              User doesn't have any cards in wayforpay. Please add one to
              continue.
            </p>
          )}
        </div>
        {creditCard ? (
          <Button
            className="p-button-success"
            onClick={() => tryRefillWallet()}
            label="Send"
          />
        ) : null}
      </>
    )
  }
)

const withData = inject((stores) => ({
  creditCardsList: stores.WayForPayStore.creditCardsList,
  getCreditCards: stores.WayForPayStore.getCreditCards,
  refillWallet: stores.WayForPayStore.refillWallet,
}))

export default withData(WayForPayBalance)
