import {
  MAX_USER_NAME_LENGTH,
  MIN_USER_NAME_LENGTH,
  MIN_PASSWORD_LENGTH,
  MAX_PASSWORD_LENGTH,
} from "./user"

import {
  BANK_ACCOUNT_LENGTH,
  BANK_CODE_LENGTH,
  PRODUCT_NAME_MIN_LENGTH,
  PRODUCT_NAME_MAX_LENGTH,
  PRODUCT_DESCRIPTION_MIN_LENGTH,
  PRODUCT_DESCRIPTION_MAX_LENGTH,
  REG_NUMBER_LENGTH,
  COMPANY_NAME_MIN,
  COMPANY_NAME_MAX,
  COMPANY_ADDRESS_MIN,
  COMPANY_ADDRESS_MAX,
  COMPANY_ALIAS_MIN,
  COMPANY_ALIAS_MAX,
} from "./merchant"

import {
  MIN_WALLET_NAME_LENGTH,
  MAX_WALLET_NAME_LENGTH
} from "./wallets"

export const Messages = {
  /**
   * Validation
   */
  VALIDATION_ERROR: "Request semantically erroneous",
  EMAIL_REQUIRED: "Please enter your Email.",
  FIRST_NAME_REQUIRED: "Please enter your First Name.",
  LAST_NAME_REQUIRED: "Please enter your Last Name.",
  PHONE_REQUIRED: "Please enter your Phone Number.",
  PASSWORD_REQUIRED: `Please enter your Password (from ${MIN_PASSWORD_LENGTH} to ${MAX_PASSWORD_LENGTH} symbols).`,
  CONFIRM_PASSWORD_REQUIRED: `Please repeat your Password.`,
  CODE_2FA_REQUIRED: `Please enter code from Google Authenticator app.`,
  BANK_ACCOUNT_REQUIRED: "Please enter bank account.",
  BANK_CODE_REQUIRED: "Please enter bank code.",
  IBAN_REQUIRED: "Please enter IBAN",
  PRODUCT_NAME_REQUIRED: "Please enter product name",
  PRODUCT_DESCRIPTION_REQUIRED: "Please describe your product",
  PRODUCT_PRICE_REQUIRED: "Please enter product price",
  COMPANY_NAME_REQUIRED: "Please enter company name",
  COMPANY_ALIAS_REQUIRED: "Please enter company alias",
  ADDRESS_REQUIRED: "Please enter address",
  REG_NUMBER_REQUIRED: "Please enter company registration number",
  TAX_ID_REQUIRED: "Please enter company tax ID",
  COUTRY_ID_REQUIRED: "Please select country",
  CURRENCY_ID_REQUIRED: "Please select currency",
  PRODUCT_TYPE_REQUIRED: "Please select type of product",
  RESET_TOKEN_REQUIRED: "Reset token is required value",

  /**
   * USER
   * */
  USER_NOT_FOUND: "User not found",
  EMAIL_NOT_VALID: "Email not valid",
  PHONE_NOT_VALID: "Phone not valid",
  FIRST_NAME_NOT_VALID: `First Name minimum length is ${MIN_USER_NAME_LENGTH} characters, maximum length is ${MAX_USER_NAME_LENGTH}. Can only contain latin letters and "-" symbol.`,
  LAST_NAME_NOT_VALID: `Last Name minimum length is ${MIN_USER_NAME_LENGTH} characters, maximum length is ${MAX_USER_NAME_LENGTH}. Can only contain latin letters and "-" symbol.`,
  CURRENT_PASSWORD_NOT_VALID: `Current password minimum length is ${MIN_PASSWORD_LENGTH} characters, maximum length is ${MAX_PASSWORD_LENGTH}. Some of symbol(s) have to be number(s) and letter(s)`,
  NEW_PASSWORD_NOT_VALID: `New password minimum length is ${MIN_PASSWORD_LENGTH} characters, maximum length is ${MAX_PASSWORD_LENGTH}. Some of symbol(s) have to be number(s) and letter(s)`,
  PASSWORD_NOT_VALID: `Some of password symbol(s) have to be number(s) and letter(s)`,
  WRONG_PASSWORD: `Wrong password`,
  PASSWORD_NOT_MATCH: "Passwords don't match",
  CONFIRM_PASSWORD_NOT_VALID: "Confirm password not valid",
  USER_ALREADY_EXIST: "User already exists",
  USER_EMAIL_ALREADY_EXIST: "User with such email already exists",
  USER_PHONE_ALREADY_EXIST: "User with such phone already exists",
  USER_NOT_ADMIN: "User without admin rights",

  /**
   * MERCHANT
   * */
  BANK_ACCOUNT_NOT_VALID: `Bank account is not valid, required length is ${BANK_ACCOUNT_LENGTH} symbols and it may contain digits only`,
  BANK_CODE_NOT_VALID: `Bank code is not valid, required length is ${BANK_CODE_LENGTH} symbols and it may contain digits only`,
  IBAN_NOT_VALID: `IBAN code is invalid, please check all digits and characters in your IBAN code`,
  PRODUCT_NAME_NOT_VALID: `Minimum length is ${PRODUCT_NAME_MIN_LENGTH} characters, maximum length is ${PRODUCT_NAME_MAX_LENGTH}.`,
  PRODUCT_DESCRIPTION_NOT_VALID: `Minimum length is ${PRODUCT_DESCRIPTION_MIN_LENGTH} characters, maximum length is ${PRODUCT_DESCRIPTION_MAX_LENGTH}.`,
  PRODUCT_PRICE_NOT_VALID: `Only positive numbers is acceptable, fractional step is 0.01 .`,
  REG_NUMBER_NOT_VALID: `Registration number  not valid, required length is ${REG_NUMBER_LENGTH} symbols and it may contain digits only`,
  TAX_ID_NOT_VALID: `Tax ID is invalid, acceptable length 8-10 or 12 symbols and it may contain digits only`,
  COMPANY_NAME_NOT_VALID: `Minimum length is ${COMPANY_NAME_MIN} characters, maximum length is ${COMPANY_NAME_MAX}.`,
  COMPANY_ALIAS_NOT_VALID: `Minimum length is ${COMPANY_ALIAS_MIN} characters, maximum length is ${COMPANY_ALIAS_MAX}.`,
  ADDRESS_NOT_VALID: `Minimum length is ${COMPANY_ADDRESS_MIN} characters, maximum length is ${COMPANY_ADDRESS_MAX}.`,

  /**
   * WALLETS
   * */
  WALLETS_NAME_REQUIRED: 'Please enter wallet name',
  WALLETS_CURRENCY_REQUIRED: 'Please select currency',
  WALLETS_NAME_NOT_VALID: 'The wallet name must have only Latin characters and numbers',
  WALLETS_NAME_MIN_VALID: `Minimum length is ${MIN_WALLET_NAME_LENGTH} characters`,
  WALLETS_NAME_MAX_VALID: `Maximum length is ${MAX_WALLET_NAME_LENGTH} characters`,
  WALLETS_AMOUNT_VALID: 'Minimum amount 0.1'
}
