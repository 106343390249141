import React from "react"
import { InputText } from "primereact/inputtext"
import { Message } from "primereact/message"
import { getMessage } from "../../../services/getMessage"
import { Button } from "primereact/button"

const CustomGroupInput = ({ form, type, labelText, field, step, buttonText, handleButton }) => {
  const showError = !!(form.touched[field.name] && form.errors[field.name])
  return (
    <>
      <div className="p-inputgroup my-2">
        <InputText
          key={field.name}
          name={field.name}
          value={field.value}
          type={type}
          step={step}
          onChange={field.onChange}
          className={showError && "p-error"}
          placeholder={labelText}
        />
       <Button
         type="button"
         label={buttonText}
         className="p-button-warning"
         onClick={handleButton}
       />
      </div>
      {showError && (
        <Message severity="error" text={getMessage(form.errors[field.name])}/>
      )}
    </>
  )
}

export default CustomGroupInput
