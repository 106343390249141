import { observable, action } from "mobx"
import Http from "../../helpers/http"
import { authApi } from "../../configs/api"

class MerchantsStore {
  @observable isLoading = false
  @observable isError = false
  @observable errorMsg = false
  @observable merchantsList = []
  @observable selectedMerchantId = null
  @observable count = 0
  lastQuery = ""

  @action getMerchants = async (limit, offset, from, to) => {
    try {
      const date = from && to ? `&from=${from}&to=${to}` : ""
      this.lastQuery = `limit=${limit}&offset=${offset}${date}`
      this.isLoading = true
      this.errorMsg = false
      this.isError = false
      const response = await Http.get(
        `${authApi}/v1/merchant/get-merchants?${this.lastQuery}`,
        true
      )
      this.isLoading = false
      if (!response.message) {
        this.merchantsList = response.rows
        this.count = response.count
      } else {
        this.isError = true
        this.errorMsg = response.message
      }
    } catch (e) {
      this.isError = true
      this.errorMsg = e
      this.isLoading = false
    }
  }

  @action setSelectedMerchant = (id) => {
    this.selectedMerchantId = id
  }
}

export default new MerchantsStore()
