import React from "react"
import { ProgressSpinner } from "primereact/progressspinner"

export default (text) => (
  <div className="my-5 p-col-12 p-grid-col p-justify-center p-align-center">
    <ProgressSpinner className="p-col-12" />
    <p className="p-col-12" style={{ textAlign: "center" }}>
      {text}
    </p>
  </div>
)
