import { action, observable } from "mobx"
import Http from "../../helpers/http"
import { authApi } from "../../configs/api"
import { getMessage } from "../../services/getMessage"

class AuthStore {
  @observable isLoading = false
  @observable user = null
  @observable isLogin = null
  @observable isError = false
  @observable enabled2fa = false
  @observable enabledSms = false
  @observable errorMsg = null

  @action
  clearErrors = () => {
    this.isLoading = false
    this.errorMsg = null
    this.isError = false
  }

  @action
  tryAuth = async (data) => {
    try {
      this.isLoading = true
      const response = await Http.post(`${authApi}/login`, data)
      if (!response.message) {
        if (response.enabled2fa) {
          this.enabled2fa = true
          this.enabledSms = false
        } else if (response.enabledSms) {
          this.enabled2fa = false
          this.enabledSms = true
        } else {
          this.enabled2fa = false
          this.enabledSms = false
          localStorage.setItem("token", response.token)
          localStorage.setItem("refreshToken", response.refreshToken)
          this.user = response.user
        }
        this.isLogin = true
        this.errorMsg = null
        this.isError = false
      } else {
        this.errorMsg = getMessage(response.message)
        this.isError = true
      }
      this.isLoading = false
    } catch (e) {
      this.isLoading = false
      this.errorMsg = e
      this.isError = true
      this.enabled2fa = false
      this.enabledSms = false
    }
  }

  @action
  generate2FA = async () => {
    try {
      const response = await Http.get(`${authApi}/v1/2fa/generate`, true, true)
      return URL.createObjectURL(response)
    } catch (e) {
      this.errorMsg = e
      this.isError = true
    }
  }

  @action
  generateSms = async (data) => {
    try {
      const response = await Http.post(`${authApi}/v1/2fa/generate-sms`, data)
      if (response && response.message) {
        this.errorMsg = getMessage(response.message)
        this.isError = true
      } else {
        this.errorMsg = null
        this.isError = false
      }
    } catch (e) {
      this.errorMsg = e
      this.isError = true
    }
  }

  @action
  logout = async () => {
    await Http.post(`${authApi}/v1/token/reject`)
    localStorage.removeItem("token")
    localStorage.removeItem("refreshToken")
    this.isLogin = false
    this.user = null
  }

  @action
  currentAuthenticatedUser = async () => {
    this.isLoading = true
    const response = await Http.get(`${authApi}/v1/user`, true)
    if (!response.message) {
      this.user = response.result
      this.isLogin = true
    } else {
      this.isLogin = false
    }
    this.isLoading = false
    return this.isLogin
  }

  @action
  createAccount = async (data) => {
    try {
      this.isLoading = true
      const response = await Http.post(`${authApi}/user/add`, data)
      this.isLoading = false

      if (response.message) {
        this.errorMsg = getMessage(response.message)
        this.isError = true
        return false
      }

      this.errorMsg = null
      this.isError = false
      window.location.href = "/"
    } catch (e) {
      this.isLoading = false
      this.errorMsg = e
      this.isError = true
    }
  }

  @action
  forgotPassword = async (data) => {
    try {
      this.isLoading = true
      const response = await Http.post(
        `${authApi}/reset-password/send`,
        data,
        false
      )
      if (response && response.message) {
        this.isLoading = false
        this.isError = true
        this.errorMsg = getMessage(response.message)
      } else {
        this.isLoading = false
        this.isError = false
        this.errorMsg = null
      }
    } catch (e) {
      this.isLoading = false
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  changePasswordByToken = async (data) => {
    try {
      this.isLoading = true
      const response = await Http.post(
        `${authApi}/reset-password/approve`,
        data,
        false
      )
      if (response && response.message) {
        this.isLoading = false
        this.isError = true
        this.errorMsg = getMessage(response.message)
      } else {
        this.isLoading = false
        this.isError = false
        this.errorMsg = null
        window.location.href = "/"
      }
    } catch (e) {
      this.isLoading = false
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  updateUserInfo = async (userInfo) => {
    try {
      this.isLoading = true
      const response = await Http.post(
        `${authApi}/v1/user/update`,
        userInfo,
        true
      )
      if (response && response.message) {
        this.isLoading = false
        this.isError = true
        this.errorMsg = getMessage(response.message)
      } else {
        this.user = response.result
        this.isLoading = false
        this.isError = false
        this.errorMsg = null
      }
    } catch (e) {
      this.isLoading = false
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  updateSettings = async (data) => {
    try {
      this.isLoading = true
      const response = await Http.post(
        `${authApi}/v1/user/settings`,
        data,
        true
      )
      if (response && response.message) {
        this.isLoading = false
        this.isError = true
        this.errorMsg = getMessage(response.message)
      } else {
        this.user = response.result
        this.isLoading = false
        this.isError = false
        this.errorMsg = null
      }
    } catch (e) {
      this.isLoading = false
      this.isError = true
      this.errorMsg = e
    }
  }
}

export default new AuthStore()
