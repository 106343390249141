import React, { useEffect } from "react"
import { observer, inject } from "mobx-react"
import Preloader from "../../components/Preloader"
import ProductPlate from "./components/ProductPlate"

const MainPage = observer(
  ({ isLoading, productsList, currencyList, getProducts }) => {
    useEffect(() => {
      getProducts()
    }, [getProducts])

    if (isLoading) {
      return Preloader("Loading products ...")
    }

    return (
      <>
        <div className="p-grid">
          {productsList.map((product) => (
            <ProductPlate
              product={product}
              currency={currencyList.find(
                ({ id }) => product.currencyId === id
              )}
            />
          ))}
        </div>
      </>
    )
  }
)

const withData = inject((stores) => ({
  isLoading: stores.MainProductsStore.isLoading,
  productsList: stores.MainProductsStore.productsList,
  currencyList: stores.MainProductsStore.currencyList,
  getProducts: stores.MainProductsStore.getProducts,
}))

export default withData(MainPage)
