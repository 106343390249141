import { observable, action, computed } from "mobx"
import Http from "../../../helpers/http"
import { authApi } from "../../../configs/api"

class MerchantProductsStore {
  @observable isLoading = false
  @observable isError = false
  @observable errorMsg = false
  @observable productsList = []
  @observable selectedProductId = null
  @observable walletsList = []
  @observable currencyList = []

  @computed get availableCurrencies() {
    return this.currencyList.filter(({ id }) =>
      this.walletsList.find((wallet) => wallet.currencyCode === id)
    )
  }

  @action setSelectedProduct = (id) => {
    this.selectedProductId = id
  }
  @action setUpdatedProduct = (updatedProduct) => {
    this.productsList = this.productsList.map((product) =>
      product.id === updatedProduct.id ? updatedProduct : product
    )
  }
  @action newProduct = (newProduct) => {
    this.productsList = [...this.productsList, newProduct]
  }

  @action getProductsAndCurrencies = async (merchantId) => {
    try {
      this.isLoading = true
      this.errorMsg = false
      this.isError = false
      const response = await Promise.all([
        Http.get(`${authApi}/v1/wallets/merchant/${merchantId}`, true),
        Http.get(`${authApi}/v1/currency/get-all`, true),
        Http.get(
          `${authApi}/v1/merchant/product/get-products/${merchantId}`,
          true
        ),
      ])
      this.isLoading = false
      if (
        !response[0]["message"] &&
        !response[1]["message"] &&
        !response[2]["message"]
      ) {
        this.walletsList = response[0]
        this.currencyList = response[1]["result"]
        this.productsList = response[2]["rows"]
        this.isError = false
        this.errorMsg = null
      } else {
        this.isError = true
        this.errorMsg = response.message
      }
    } catch (e) {
      this.isError = true
      this.errorMsg = e
      this.isLoading = false
    }
  }
}

export default new MerchantProductsStore()
