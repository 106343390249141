export const BANK_ACCOUNT_REGEXP = /^[0-9]{14}$/
export const BANK_CODE_REGEXP = /^[0-9]{6}$/
export const PRICE_REGEXP = /^\s*(?=.*[1-9])\d*(?:\.\d{0,2})?\s*$/g
export const PHONE_REGEXP = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
export const REG_NUMBER_REGEXP = /(^[0-9]{8})$/
export const TAX_ID_REGEXP = /(^[0-9]{8,10}$|^[0-9]{12}$)/

export const BANK_ACCOUNT_LENGTH = 14
export const BANK_CODE_LENGTH = 6
export const REG_NUMBER_LENGTH = 8
export const PRODUCT_NAME_MIN_LENGTH = 5
export const PRODUCT_NAME_MAX_LENGTH = 15
export const PRODUCT_DESCRIPTION_MIN_LENGTH = 100
export const PRODUCT_DESCRIPTION_MAX_LENGTH = 800

export const COMPANY_NAME_MIN = 5
export const COMPANY_NAME_MAX = 254
export const COMPANY_ADDRESS_MIN = 5
export const COMPANY_ADDRESS_MAX = 254
export const COMPANY_ALIAS_MIN = 5
export const COMPANY_ALIAS_MAX = 254
