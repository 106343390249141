import { observable, action } from "mobx"
import Http from "../../../helpers/http"
import { authApi } from "../../../configs/api"
import MerchantUsersStore from "../MerchantUsers/mobx"

class AddMerchantUserStore {
  @observable isError = false
  @observable errorMsg = false
  @observable isLoading = false
  @observable creatingUser = false
  @observable selectedTemplateIndex = null
  @observable templates = []
  @observable templatePermissions = {}
  @observable msg

  @action handleTemplateSelection = (index) => {
    this.selectedTemplateIndex = index
    this.templatePermissions = index ? this.templates[index]["permissions"] : {}
  }

  @action clearMessages = () => {
    this.errorMsg = null
    this.msg = null
  }

  @action createMerchantUser = async (values, id) => {
    try {
      const { email, manageUsers, managePayment, uploadDocuments } = values
      const reqBody = this.selectedTemplateIndex
        ? {
            merchantId: id,
            email,
            templateId: this.templates[this.selectedTemplateIndex]["id"],
          }
        : {
            merchantId: id,
            email,
            permissions: {
              manageUsers,
              managePayment,
              uploadDocuments,
            },
          }
      this.errorMsg = false
      this.isError = false
      this.creatingUser = true
      const response = await Http.post(
        `${authApi}/v1/merchant/user/add`,
        reqBody,
        true
      )
      this.creatingUser = false
      if (!response.message) {
        this.msg = "New User created"
        MerchantUsersStore.newUser(response)
      } else {
        this.isError = true
        this.errorMsg = response.message
      }
    } catch (e) {
      this.creatingUser = false
      this.isError = true
      this.errorMsg = e
    }
  }

  @action getTemplates = async () => {
    try {
      this.errorMsg = false
      this.isError = false
      this.isLoading = true
      const response = await Http.get(
        `${authApi}/v1/merchant/user-template/get-templates`,
        true
      )
      this.isLoading = false
      if (!response.message) {
        this.templates = response.rows
      } else {
        this.isError = true
        this.errorMsg = response.message
      }
    } catch (e) {
      this.isLoading = false
      this.isError = true
      this.errorMsg = e
    }
  }
}

export default new AddMerchantUserStore()
