import React from "react"
import { Message } from "primereact/message"
import { Calendar } from "primereact/calendar"
import { getMessage } from "../../../services/getMessage"

const CustomCalendar = ({ form, type, labelText, field }) => {
  const showError = !!(form.touched[field.name] && form.errors[field.name])
  return (
    <>
      <span className="p-float-label my-2">
        <Calendar
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          className={showError && "p-error"}
          dateFormat="dd/mm/yy"
          selectionMode="range"
          readOnlyInput
          showButtonBar
          responsive
          showIcon
        />
        <label htmlFor={field.name}>{labelText}</label>
      </span>
      {showError && (
        <Message severity="error" text={getMessage(form.errors[field.name])}/>
      )}
    </>
  )
}

export default CustomCalendar
