import React, { useEffect, useState } from "react"
import { withRouter } from "react-router"
import { observer, inject } from "mobx-react"
import moment from "moment"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

import Preloader from "../../Preloader"
import UpdateProduct from "../UpdateProduct"

const ProductsList = observer(
  ({ match, isLoading, productsList, getProductsAndCurrencies }) => {
    const [expandedRows, onRowToggle] = useState({})
    const merchantId = Number(match.params.id)
    useEffect(() => {
      getProductsAndCurrencies(merchantId)
    }, [getProductsAndCurrencies, merchantId])

    const formattedDate = (rowData) => {
      return <>{moment(rowData.createdAt).format("DD/MM/YYYY HH:mm:ss")}</>
    }

    const formattedPrice = ({ price }) => {
      if ((price / 100) % 1) {
        return (price / 100).toString().padEnd(price.toString().length + 1, "0")
      }
      return `${price / 100}.00`
    }
    const approveStatuses = [
      "Not published",
      "Publish requested",
      "Product published",
      "Publish rejected",
    ]
    const formattedStatus = (rowData) => {
      return <>{approveStatuses[rowData.approved]}</>
    }

    return (
      <>
        {isLoading ? (
          Preloader("Loading...")
        ) : (
          <DataTable
            emptyMessage="Products not found"
            className="mt-2"
            expandedRows={expandedRows}
            value={productsList}
            onRowDoubleClick={({ data }) => {
              onRowToggle({ [data.id]: true })
            }}
            rowExpansionTemplate={({ id }) => {
              return <UpdateProduct productId={id} />
            }}
            dataKey="id"
          >
            <Column key="id" field="id" header="ID" />
            <Column key="name" field="name" header="Product Name" />
            <Column
              key="price"
              field="price"
              header="Price"
              body={formattedPrice}
            />
            <Column
              key="approved"
              field="approved"
              header="Approved"
              body={formattedStatus}
            />
            <Column
              key="onHold"
              field="onHold"
              header="Blocked"
              body={({ onHold }) => (onHold ? "Blocked" : "Not Blocked")}
            />
            <Column
              key="createdAt"
              field="createdAt"
              header="Date"
              body={formattedDate}
            />
          </DataTable>
        )}
      </>
    )
  }
)

const withData = inject((stores) => ({
  merchantId: stores.MerchantsStore.selectedMerchantId,
  isLoading: stores.MerchantProductsStore.isLoading,
  productsList: stores.MerchantProductsStore.productsList,
  getProductsAndCurrencies:
    stores.MerchantProductsStore.getProductsAndCurrencies,
}))

export default withData(withRouter(ProductsList))
