import { observable, action } from "mobx"
import Http from "../../helpers/http"
import { authApi } from "../../configs/api"

class WalletInfoStore {
  @observable isLoading = false
  @observable isError = false
  @observable errorMsg = null
  @observable walletData = {}

  @action
  getWalletInfo = async (walletId, dopParam) => {
    try {
      this.isLoading = true
      const response = await Http.get(
        `${authApi}/v1/transaction/wallet/${walletId}${dopParam}`,
        true)
      this.isLoading = false
      if (response.message) {
        this.errorMsg = `${response.message}`
        this.isError = true
      } else {
        this.walletData = response
      }
      this.isError = false
      this.errorMsg = ""
    } catch (e) {
      this.isLoading = false
      this.errorMsg = `${e}`
    }
  }
}

export default new WalletInfoStore()