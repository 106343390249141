import { observable, action } from "mobx"
import Http from "../../helpers/http"
import { authApi } from "../../configs/api"

class WayForPayStore {
  @observable isLoading = false
  @observable isError = false
  @observable errorMsg = null
  @observable creditCardsList = []
  @observable addCcForm = ""
  @observable cardForm = ""

  @action
  getCreditCards = async () => {
    try {
      this.isLoading = true
      const response = await Http.get(`${authApi}/v1/wayforpay/my-cards`, true)
      this.creditCardsList = response.result
      this.isLoading = false
      this.isError = false
      this.errorMsg = null
    } catch (e) {
      this.isLoading = false
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  addCreditCard = async () => {
    try {
      const response = await Http.get(`${authApi}/v1/wayforpay/addcard`, true)
      const { formCode } = response
      if (formCode) {
        this.addCcForm = formCode
      }
    } catch (e) {
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  deleteCard = async (id) => {
    try {
      const response = await Http.delete(
        `${authApi}/v1/wayforpay/deleteCard?cid=${id}`,
        true
      )
      if (response.status === "Success") {
        this.getCreditCards()
      }
    } catch (e) {
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  getCardForm = async () => {
    try {
      const tokenForQuery = localStorage.getItem("token").split(" ")
      const response = await Http.get(
        `${authApi}/v1/wayforpay/addcard?token=${tokenForQuery[1]}`,
        false
      )

      this.cardForm = response.formCode
    } catch (e) {
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  refillWallet = async (wallet, amount, recToken, comment, currency) => {
    try {
      await Http.post(
        `${authApi}/v1/wayforpay/charge`,
        { wallet, amount, recToken, comment, currency },
        true
      )
      // const { status } = response
      // if (status === "Success") {
      //   this.operationComplite(true)
      //   this.getWallets()
      //   setTimeout(() => this.operationComplite(false), 1000)
      // }
    } catch (e) {
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  moneyOut = async (wallet, amount, recToken, comment) => {
    try {
      await Http.post(
        `${authApi}/v1/wayforpay/toCard`,
        { wallet, amount, recToken, comment },
        true
      )
      // const { status } = response
      // if (status === "accept") {
      //   this.operationComplite(true)
      //   this.getWallets()
      //   setTimeout(() => this.operationComplite(false), 1000)
      // }
    } catch (e) {
      this.isError = true
      this.errorMsg = e
    }
  }
}

export default new WayForPayStore()
