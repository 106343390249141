import React from "react"
import { InputSwitch } from "primereact/inputswitch"
import { Message } from "primereact/message"
import { getMessage } from "../../../services/getMessage"

const CustomSwitch = ({ form, labelText, field, onChange }) => {
  const showError = !!(form.touched[field.name] && form.errors[field.name])
  return (
    <>
      <div className="my-2 p-align-center p-flex">
        <InputSwitch
          name={field.name}
          checked={field.value}
          onChange={(value) => {
            field.onChange(value)
            if (onChange) onChange(value)
          }}
        />
        <span className="ml-3">{labelText}</span>
      </div>
      {showError && (
        <Message severity="error" text={getMessage(form.errors[field.name])}/>
      )}
    </>
  )
}

export default CustomSwitch
