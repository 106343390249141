import { observable, action } from "mobx"
import Http from "../../helpers/http"
import { authApi } from "../../configs/api"

class CreateMerchantStore {
  @observable isLoading = false
  @observable isCreated = false
  @observable isError = false
  @observable errorMsg = null
  @observable countriesList =[]


  @action getCountries = async () => {
    try {
      const response = await Http.get(`${authApi}/v1/country/all`, true)
      if (response.message) {
        this.errorMsg = response.message
        this.isError = true
      } else {
        this.countriesList = response.result
      }
    } catch (e) {
      this.errorMsg = e
      this.isError = true
    }

  }

  @action
  tryCreateMerchant = async (values) => {
    try {
      this.isError = false
      this.isCreated = false
      this.isLoading = true
      const response = await Http.post(`${authApi}/v1/merchant/add`, values, true)
      if (response.message) {
        this.errorMsg = response.message
        this.isError = true
      } else {
        this.isCreated = true
      }
      this.isLoading = false
    } catch (e) {
      this.isLoading = false
      this.errorMsg = e
      this.isError = true
    }
  }

}
export default new CreateMerchantStore()
