import React, { useEffect, useState, useRef } from "react"
import { withRouter } from "react-router"
import { observer, inject } from "mobx-react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import Preloader from "../../Preloader"
import UpdateMerchantUser from "../UpdateMerchantUser"
import "./UsersList.scss"

const UsersList = observer(({ match, isLoading, usersList, getUsers }) => {
  const dt = useRef(null)
  const [expandedRows, onRowToggle] = useState({})
  const id = Number(match.params.id)
  useEffect(() => {
    getUsers(id)
  }, [getUsers, id])

  const cols = [
    { field: "id", header: "Merchant User id" },
    { field: "email", header: "Email" },
    { field: "userId", header: "User Id" },
    { field: "MerchantUserTemplateId", header: "Template Id" },
  ]

  const dynamicColumns = cols.map((col) => {
    return <Column key={col.field} field={col.field} header={col.header} />
  })

  const table = (
    <DataTable
      value={usersList}
      emptyMessage="Users not found"
      rowClassName={(rowData) => {
        return expandedRows[rowData.id] ? { tr_overflow: true } : null
      }}
      ref={dt}
      dataKey="id"
      expandedRows={expandedRows}
      onRowDoubleClick={({ data }) => {
        onRowToggle({ [data.id]: true })
      }}
      rowExpansionTemplate={({ id }) => {
        return <UpdateMerchantUser userId={id} />
      }}
    >
      {dynamicColumns}
    </DataTable>
  )

  return (
    <div className="p-grid">
      {isLoading ? Preloader("Loading users...") : table}
    </div>
  )
})

const withData = inject((stores) => ({
  id: stores.MerchantsStore.selectedMerchantId,
  isLoading: stores.MerchantUsersStore.isLoading,
  usersList: stores.MerchantUsersStore.usersList,
  getUsers: stores.MerchantUsersStore.getUsers,
  setSelectedUser: stores.MerchantUsersStore.setSelectedUser,
  selectedUserId: stores.MerchantUsersStore.selectedUserId,
}))

export default withData(withRouter(UsersList))
