import AuthStore from "./pages/LoginPage/mobx"
import ChangePasswordStore from "./pages/UserInfo/mobxChangePassword"
import ProductStore from "./components/Products/mobx"
import CreateMerchantStore from "./pages/CreateMerchant/mobx"
import MerchantsStore from "./pages/MerchantsList/mobx"
import MerchantInfoStore from "./components/Merchants/MerchantInfo/mobx"
import AddPaymentInfoStore from "./components/Merchants/AddPaymentInfo/mobx"
import AddMerchantUserStore from "./components/Merchants/AddMerchantUser/mobx"
import WalletsStore from "./components/Wallets/mobx"
import MerchantUsersStore from "./components/Merchants/MerchantUsers/mobx"
import UpdateMerchantUserStore from "./components/Merchants/UpdateMerchantUser/mobx"
import MerchantMessagesStore from "./components/Merchants/MerchantMessages/mobx"
import MerchantsDocumentsStore from "./components/Merchants/MerchantsDocuments/mobx"
import MerchantAddProductStore from "./components/Merchants/AddProduct/mobx"
import MerchantProductsStore from "./components/Merchants/ProductsList/mobx"
import MerchantUpdateProductStore from "./components/Merchants/UpdateProduct/mobx"
import StripeStore from "./components/Stripe/mobx"
import WayForPayStore from "./components/WayForPay/mobx"
import LiqPayStore from "./components/LiqPay/mobx"
import MainProductsStore from "./pages/MainPage/mobx"
import MerchantPaymentStore from "./components/Merchants/PaymentList/mobx"
import WalletInfoStore from "./pages/WalletInfoPage/mobx"

export default {
  AuthStore,
  ChangePasswordStore,
  WalletsStore,
  ProductStore,
  CreateMerchantStore,
  MerchantsStore,
  MerchantInfoStore,
  AddPaymentInfoStore,
  AddMerchantUserStore,
  MerchantUsersStore,
  UpdateMerchantUserStore,
  MerchantMessagesStore,
  MerchantsDocumentsStore,
  MerchantAddProductStore,
  MerchantProductsStore,
  MerchantUpdateProductStore,
  StripeStore,
  WayForPayStore,
  LiqPayStore,
  MainProductsStore,
  MerchantPaymentStore,
  WalletInfoStore,
}
