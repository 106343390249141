import React, { useEffect, useState, useRef } from "react"
import { inject, observer } from "mobx-react"
import { useLocation } from 'react-router-dom'
import { Button } from "primereact/button"

import WalletsForm from "./WalletsForm"
import WalletsGrid from "./WalletsGrig"

import { Growl } from "primereact/growl"

const Wallets = observer(
  ({ getCurrency, isError, errorMsg }) => {
    const location = useLocation();
    const [showForm, setShowForm] = useState(false)
    const [merchantId] = useState(
      location.pathname !== '/wallets' ? Number(location.pathname.replace('/merchant/', '')) : 0
    )
    const [isMerchant] = useState(location.pathname !== '/wallets')

    let infoMessage = useRef(null)

    if (isError) {
      infoMessage.current.show([
        { severity: "error", detail: errorMsg, closable: false },
      ])
    }

    const buttonAddForm = (
      <Button
        type="submit"
        label="Create new Wallet"
        style={{width: '100%'}}
        onClick={() => setShowForm(true)}
      />
    )

    useEffect(() => {
      getCurrency()
    }, [getCurrency])

    return (
      <div className="content-section">
        <div className="p-grid p-mt-3" style={{width: '100%'}}>
          <div className="p-col-12 p-lg-9">
            <div className="p-grid">
              <WalletsGrid isMerchant={isMerchant ? merchantId : false} />
            </div>
          </div>
          <div className="p-col-12 p-lg-3">
            {showForm ? (
              <WalletsForm isMerchant={isMerchant ? merchantId : false} />
            ) : (
              buttonAddForm
            )}
          </div>
        </div>
        <Growl ref={infoMessage} />
      </div>
    )
  }
)

const withData = inject((store) => ({
  merchantId: store.MerchantsStore.selectedMerchantId,
  isLoading: store.WalletsStore.isLoading,
  getCurrency: store.WalletsStore.getCurrency,
  isError: store.WalletsStore.isError,
  errorMsg: store.WalletsStore.errorMsg,
}))

export default withData(Wallets)
