import { observable, action } from "mobx"
import Http from "../../../helpers/http"
import { authApi } from "../../../configs/api"
import MerchantInfoStore from "../MerchantInfo/mobx"

class MerchantsDocumentsStore {
  @observable agentDocs = []
  @observable isError = false
  @observable errorMsg = null
  @observable isUploading = null
  @observable fileErrorMsg = null

  @action
  uploadDocs = async (merchantId, docs) => {
    try {
      this.fileErrorMsg = null
      this.errorMsg = false
      this.isError = false
      this.isUploading = true
      const result = await Promise.all([
        ...docs.map((file) => this.uploadFile(file)),
      ])
      const succsessUpload = result.filter((response) => !response.message)
      const failureUpload = result.filter((response) => response.message)
      const updatedMerchant = await Http.post(
        `${authApi}/v1/merchant/documents/add`,
        {
          fileNames: succsessUpload.map(({ filename }) => filename),
          merchantId,
        },
        true
      )
      if (updatedMerchant.message) {
        this.isUploading = false
        this.isError = true
        this.errorMsg = updatedMerchant.message
        return
      }
      if (failureUpload.length) {
        this.fileErrorMsg = `This files were rejected due to size:${failureUpload.reduce(
          (acc, file) => acc + "\n" + file.filename,
          ""
        )} `
      }
      MerchantInfoStore.updateMerchant(updatedMerchant)
      this.isUploading = false
    } catch (e) {
      this.isUploading = false
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  uploadFile = async (file) => {
    try {
      const response = await Http.post(`${authApi}/v1/file/upload`, file, true)

      if (!response.message) {
        return response
      }
      if (response.message.status === 413) {
        response.filename = file.filename
        return response
      } else {
        this.errorMsg = response.message
        this.isError = true
      }
    } catch (e) {
      this.errorMsg = e
      this.isError = true
    }
  }
}

export default new MerchantsDocumentsStore()
