import { observable, action } from "mobx";
import Http from "../../../helpers/http";
import { authApi } from "../../../configs/api";

class MerchantInfoStore {
  @observable isError = false;
  @observable errorMsg = false;
  @observable merchant = null;
  @observable isLoading = false;

  @action updateMerchant = (merchantUpdated) => {
    this.merchant = merchantUpdated;
  };
  @action getMerchant = async (id) => {
    try {
      this.errorMsg = false;
      this.isError = false;
      this.isLoading = true;
      const response = await Http.get(`${authApi}/v1/merchant/get/${id}`, true);
      this.isLoading = false;
      if (!response.message) {
        this.merchant = response;
      } else {
        this.isError = true;
        this.errorMsg = response.message;
      }
    } catch (e) {
      this.isLoading = false;
      this.isError = true;
      this.errorMsg = e;
    }
  };
}

export default new MerchantInfoStore();
