import { observable, action, computed } from "mobx"
import Http from "../../../helpers/http"
import { authApi } from "../../../configs/api"
import MerchantProductsStore from "../ProductsList/mobx"

const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onerror = reject

    reader.readAsArrayBuffer(file)
  })
}

const arrayBufferToBase64 = (buffer) => {
  let binary = ""
  let bytes = new Uint8Array(buffer)
  let len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

const fileToBase64 = async (file) => {
  const fileBuffer = await readFileAsync(file)
  const fileBase64 = arrayBufferToBase64(fileBuffer)
  return { file: fileBase64, filename: file.name }
}

class MerchantUpdateProductStore {
  @observable isError = false
  @observable errorMsg = false
  @observable isLoading = true
  @observable updatingProduct = false
  @observable product = {}
  @observable image = null
  @observable isRequestingPublishing = false
  @observable isLoadingWidget = false
  @observable productWidget = null

  @computed get imageChanged() {
    return (
      (this.product.image && `${authApi}/v1/img/${this.product.image}`) !==
      (this.image && this.image.objectURL)
    )
  }

  @action setImage = (image) => {
    this.image = image
  }
  @action clearImage = () => {
    this.image = null
  }

  @action getProduct = async (productId) => {
    try {
      this.errorMsg = false
      this.isError = false
      this.isLoading = true
      const response = await Http.get(
        `${authApi}/v1/merchant/product/get/${productId}`,
        true
      )
      this.isLoading = false
      if (response.message) {
        this.isError = true
        this.errorMsg = response.message
      } else {
        response.price /= 100
        this.product = response
        if (response.image) {
          this.image = { objectURL: `${authApi}/v1/img/${response.image}` }
        }
      }
    } catch (e) {
      this.isLoading = false
      this.isError = true
      this.errorMsg = e
    }
  }
  @action updateProduct = async (values, merchantId) => {
    try {
      this.errorMsg = false
      this.isError = false
      this.updatingProduct = true
      let imgUpload = this.product.image
      if (this.imageChanged) {
        imgUpload = this.image
          ? (
              await Http.post(
                `${authApi}/v1/img/upload`,
                await fileToBase64(this.image),
                true
              )
            )["filename"]
          : null
      }
      const response = await Http.post(
        `${authApi}/v1/merchant/product/update`,
        { ...values, productId: this.product.id, merchantId, image: imgUpload },
        true
      )
      this.updatingProduct = false
      if (response.message) {
        this.isError = true
        this.errorMsg = response.message
      } else {
        MerchantProductsStore.setUpdatedProduct({ ...response })
        response.price /= 100
        this.product = response
      }
    } catch (e) {
      this.updatingProduct = false
      this.isError = true
      this.errorMsg = e
    }
  }

  @action requestPublishing = async (productId, merchantId) => {
    try {
      this.errorMsg = false
      this.isError = false
      this.isRequestingPublishing = true
      const response = await Http.post(
        `${authApi}/v1/merchant/product/req-publishing`,
        { productId, merchantId },
        true
      )
      this.isRequestingPublishing = false
      if (response.message) {
        this.isError = true
        this.errorMsg = response.message
      } else {
        this.product.approved = response.approved
      }
    } catch (e) {
      this.isLoading = false
      this.isError = true
      this.errorMsg = e
    }
  }

  @action getWidget = async (productId) => {
    try {
      this.errorMsg = false
      this.isError = false
      this.isLoadingWidget = true
      const response = await Http.get(
        `${authApi}/v1/merchant/product/get-payment-widget/${productId}`,
        true
      )
      this.isLoadingWidget = false
      if (response.message) {
        this.isError = true
        this.errorMsg = response.message
      } else {
        this.productWidget = response.widget
      }
    } catch (e) {
      this.isLoadingWidget = false
      this.isError = true
      this.errorMsg = e
    }
  }
  @action clearProductWidget = () => {
    this.productWidget = null
  }
}

export default new MerchantUpdateProductStore()
