import React from "react";
import { inject, observer } from "mobx-react";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { Button } from "primereact/button";
import CustomInput from "../../../components/common/CustomInput";
import { ErrorMsg } from "../../../constants/errorMsg";
import {
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
  PASSWORD_REGEXP,
} from "../../../constants/user";
import { Message } from "primereact/message"

const schema = yup.object().shape({
  currentPassword: yup
    .string()
    .trim()
    .required(ErrorMsg.PASSWORD_REQUIRED)
    .min(MIN_PASSWORD_LENGTH, ErrorMsg.CURRENT_PASSWORD_NOT_VALID)
    .max(MAX_PASSWORD_LENGTH, ErrorMsg.CURRENT_PASSWORD_NOT_VALID)
    .matches(PASSWORD_REGEXP, ErrorMsg.CURRENT_PASSWORD_NOT_VALID),
  newPassword: yup
    .string()
    .trim()
    .required(ErrorMsg.PASSWORD_REQUIRED)
    .min(MIN_PASSWORD_LENGTH, ErrorMsg.PASSWORD_NOT_VALID)
    .max(MAX_PASSWORD_LENGTH, ErrorMsg.PASSWORD_NOT_VALID)
    .matches(PASSWORD_REGEXP, ErrorMsg.PASSWORD_NOT_VALID),
  confirmPassword: yup
    .string()
    .required(ErrorMsg.CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("newPassword")], ErrorMsg.PASSWORD_NOT_MATCH),
});

const ChangePassword = observer(({ isLoading, changePassword, isError, errorMsg }) => (
  <Formik
    initialValues={{
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    }}
    validationSchema={schema}
    onSubmit={changePassword}
  >
    {({ dirty }) => (
      <Form>
        <div className="p-grid">
          {isError && errorMsg && (
            <div className="p-col-12">
              <Message severity="error" text={errorMsg}/>
            </div>
          )}
          <div className="p-col-12 p-md-6">
            <Field
              type="password"
              name="currentPassword"
              labelText="Current password"
              component={CustomInput}
            />
          </div>
          <div className="p-col-12 p-md-6">
            <Field
              type="password"
              name="newPassword"
              labelText="New password"
              component={CustomInput}
            />
          </div>
          <div className="p-col-12 p-md-6 p-md-offset-6">
            <Field
              type="password"
              name="confirmPassword"
              labelText="Confirm new password"
              component={CustomInput}
            />
          </div>
          <div className="p-col-12">
            <Button
              type="submit"
              label="Change Password"
              disabled={!(dirty && !isLoading)}
            />
          </div>
        </div>
      </Form>
    )}
  </Formik>
));

const withData = inject((stores) => ({
  isLoading: stores.ChangePasswordStore.isLoading,
  changePassword: stores.ChangePasswordStore.changePassword,
  isError: stores.ChangePasswordStore.isError,
  errorMsg: stores.ChangePasswordStore.errorMsg,
}));

export default withData(ChangePassword);
