import React, { useState } from "react"
import { inject, observer } from "mobx-react"
import { Field, Form, Formik } from "formik"
import { Button } from "primereact/button"
import CustomInput from "../../../components/common/CustomInput"
import CustomSwitch from "../../../components/common/CustomSwitch"

const PersonalSettings = observer(({ userInfo, isLoading, updateSettings, generate2FA }) => {
  const [qrBuff, setQrBuff] = useState(null)

  const getQrBuff = async (value, values, setFieldValue) => {
    let qrBuffVal = null
    if (qrBuff === null && value && userInfo.enabled2fa !== value) {
      qrBuffVal = await generate2FA()
    }
    setQrBuff(qrBuffVal)
    if (value && values.enabledSms) setFieldValue("enabledSms", false)
  }

  const handleSwitchSms = (value, values, setFieldValue) => {
    if (value && values.enabled2fa) {
      setFieldValue("enabled2fa", false)
      setQrBuff(null)
    }
  }

  return (
    <Formik
      initialValues={{
        userId: userInfo.id,
        enabledSms: userInfo.enabledSms || false,
        enabled2fa: userInfo.enabled2fa || false,
        twoFactorAuthenticationCode: ""
      }}
      enableReinitialize
      onSubmit={updateSettings}
    >
      {({ dirty, values, setFieldValue }) => (
        <Form>
          <div className="p-grid">
            <div className="p-col-12 p-md-6">
              <Field
                name="enabledSms"
                labelText="SMS 2-factor Authentication"
                component={CustomSwitch}
                onChange={({ value }) => handleSwitchSms(value, values, setFieldValue)}
              />
            </div>
            <div className="p-col-12 p-md-6">
              <Field
                name="enabled2fa"
                labelText="Google 2-factor Authentication"
                component={CustomSwitch}
                onChange={({ value }) => getQrBuff(value, values, setFieldValue)}
              />
            </div>
            {qrBuff && (
              <>
                <div className="p-col-12">
                  <img src={qrBuff} alt="2FA QR"/>
                </div>
                <div className="p-col-12 p-md-6">
                  <Field
                    name="twoFactorAuthenticationCode"
                    labelText="Password from Google Authenticator"
                    component={CustomInput}
                  />
                </div>
              </>
            )}
            <div className="p-col-12">
              <Button
                type="submit"
                label="Save"
                disabled={!(dirty && !isLoading)}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
})

const withData = inject((stores) => ({
  isLoading: stores.AuthStore.isLoading,
  userInfo: stores.AuthStore.user,
  updateSettings: stores.AuthStore.updateSettings,
  generate2FA: stores.AuthStore.generate2FA,
}))

export default withData(PersonalSettings)
