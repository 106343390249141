export const ErrorMsg = {
  /**
   * Validation
   */
  VALIDATION_ERROR: "VALIDATION_ERROR",
  EMAIL_REQUIRED: "EMAIL_REQUIRED",
  FIRST_NAME_REQUIRED: "FIRST_NAME_REQUIRED",
  LAST_NAME_REQUIRED: "LAST_NAME_REQUIRED",
  PHONE_REQUIRED: "PHONE_REQUIRED",
  PASSWORD_REQUIRED: "PASSWORD_REQUIRED",
  CONFIRM_PASSWORD_REQUIRED: "CONFIRM_PASSWORD_REQUIRED",
  CODE_2FA_REQUIRED: "CODE_2FA_REQUIRED",
  RESET_TOKEN_REQUIRED: "RESET_TOKEN_REQUIRED",

  BANK_ACCOUNT_REQUIRED: "BANK_ACCOUNT_REQUIRED",
  BANK_CODE_REQUIRED: "BANK_CODE_REQUIRED",
  IBAN_REQUIRED: "IBAN_REQUIRED",
  PRODUCT_NAME_REQUIRED: "PRODUCT_NAME_REQUIRED",
  PRODUCT_DESCRIPTION_REQUIRED: "PRODUCT_DESCRIPTION_REQUIRED",
  PRODUCT_PRICE_REQUIRED: "PRODUCT_PRICE_REQUIRED",
  COMPANY_NAME_REQUIRED: "COMPANY_NAME_REQUIRED",
  COMPANY_ALIAS_REQUIRED: "COMPANY_ALIAS_REQUIRED",
  ADDRESS_REQUIRED: "ADDRESS_REQUIRED",
  REG_NUMBER_REQUIRED: "REG_NUMBER_REQUIRED",
  TAX_ID_REQUIRED: "TAX_ID_REQUIRED",
  COUTRY_ID_REQUIRED: "COUTRY_ID_REQUIRED",
  CURRENCY_ID_REQUIRED: "CURRENCY_ID_REQUIRED",
  PRODUCT_TYPE_REQUIRED: "PRODUCT_TYPE_REQUIRED",
  COMPANY_NAME_NOT_VALID: "COMPANY_NAME_NOT_VALID",
  COMPANY_ALIAS_NOT_VALID: "COMPANY_ALIAS_NOT_VALID",
  ADDRESS_NOT_VALID: "ADDRESS_NOT_VALID",

  /**
   * USER
   * */
  USER_NOT_FOUND: "USER_NOT_FOUND",
  EMAIL_NOT_VALID: "EMAIL_NOT_VALID",
  PHONE_NOT_VALID: "PHONE_NOT_VALID",
  FIRST_NAME_NOT_VALID: "FIRST_NAME_NOT_VALID",
  LAST_NAME_NOT_VALID: "LAST_NAME_NOT_VALID",
  CURRENT_PASSWORD_NOT_VALID: "CURRENT_PASSWORD_NOT_VALID",
  NEW_PASSWORD_NOT_VALID: "NEW_PASSWORD_NOT_VALID",
  PASSWORD_NOT_VALID: "PASSWORD_NOT_VALID",
  WRONG_PASSWORD: "WRONG_PASSWORD",
  CONFIRM_PASSWORD_NOT_VALID: "CONFIRM_PASSWORD_NOT_VALID",
  PASSWORD_NOT_MATCH: "PASSWORD_NOT_MATCH",
  USER_ALREADY_EXIST: "USER_ALREADY_EXIST",
  USER_EMAIL_ALREADY_EXIST: "USER_EMAIL_ALREADY_EXIST",
  USER_PHONE_ALREADY_EXIST: "USER_PHONE_ALREADY_EXIST",
  USER_NOT_ADMIN: "USER_NOT_ADMIN",

  /**
   * MERCHANT
   * */
  BANK_ACCOUNT_NOT_VALID: "BANK_ACCOUNT_NOT_VALID",
  BANK_CODE_NOT_VALID: "BANK_CODE_NOT_VALID",
  IBAN_NOT_VALID: "IBAN_NOT_VALID",
  PRODUCT_NAME_NOT_VALID: "PRODUCT_NAME_NOT_VALID",
  PRODUCT_DESCRIPTION_NOT_VALID: "PRODUCT_DESCRIPTION_NOT_VALID",
  PRODUCT_PRICE_NOT_VALID: "PRODUCT_PRICE_NOT_VALID",
  REG_NUMBER_NOT_VALID: "REG_NUMBER_NOT_VALID",
  TAX_ID_NOT_VALID: "TAX_ID_NOT_VALID",

  /**
   * WALLETS
   * */
  WALLETS_NAME_REQUIRED: 'WALLETS_NAME_REQUIRED',
  WALLETS_CURRENCY_REQUIRED: 'WALLETS_CURRENCY_REQUIRED',
  WALLETS_NAME_NOT_VALID: 'WALLETS_NAME_NOT_VALID',
  WALLETS_NAME_MIN_VALID: 'WALLETS_NAME_MIN_VALID',
  WALLETS_NAME_MAX_VALID: 'WALLETS_NAME_MAX_VALID',
  WALLETS_AMOUNT_VALID: 'WALLETS_AMOUNT_VALID'
}
