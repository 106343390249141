import React from "react"
import { Dropdown } from "primereact/dropdown"
import { Message } from "primereact/message"
import { getMessage } from "../../../services/getMessage"

const CustomSelect = ({
  form,
  options,
  labelText,
  field,
  itemTemplate,
  optionLabel,
  optionValue,
  filterBy,
  className,
}) => {
  const showError = !!(form.touched[field.name] && form.errors[field.name])
  return (
    <>
      <span className="my-2">
        <Dropdown
          name={field.name}
          value={field.value}
          options={options}
          onChange={field.onChange}
          className={className + " " + (showError ? "p-error" : "")}
          placeholder={labelText}
          scrollHeight="200px"
          filter={true}
          itemTemplate={itemTemplate}
          optionLabel={optionLabel}
          optionValue={optionValue}
          onBlur={() => form.setFieldTouched(field.name, true, true)}
        />
      </span>
      {showError && (
        <Message severity="error" text={getMessage(form.errors[field.name])} />
      )}
    </>
  )
}

export default CustomSelect
