import React, { useEffect, useRef } from "react"
import { withRouter } from "react-router"
import { observer, inject } from "mobx-react"
import { Messages } from "primereact/messages"
import { OverlayPanel } from "primereact/overlaypanel"
import "./MerchantMessages.scss"

const MerchantMessages = observer(
  ({
    match,
    messages,
    count,
    getMessages,
    getMessagesInterval,
    setMessageNotActual,
  }) => {
    let messagesRef = useRef(null)
    let panelRef = useRef(null)
    const merchantId = Number(match.params.id)
    useEffect(() => {
      const intervalGetMessages = setInterval(() => {
        getMessagesInterval(merchantId)
      }, 60000)
      getMessages(merchantId)
      return () => clearInterval(intervalGetMessages)
    }, [getMessages, getMessagesInterval, merchantId])

    const onPannelToogle = (e) => {
      panelRef.current.toggle(e)
      if (!messagesRef.current.state.messages[0]) {
        messagesRef.current.show(
          messages.map((message) => {
            return {
              severity: "info",
              summary: "Message",
              detail: message.text,
              sticky: true,
              messageId: message.id,
            }
          })
        )
      }
    }

    const onRemoveMessage = async ({ messageId }) => {
      const hidePanel = await setMessageNotActual(messageId, merchantId)
      if (hidePanel) {
        panelRef.current.hide()
      }
    }

    const renderMessageCount = count ? (
      <div className="merchant-messages-icon" onClick={onPannelToogle}>
        <i className="pi pi-comments" style={{ color: "black" }}></i>
        <div className="merchant-messages-counter">{count}</div>
      </div>
    ) : null

    return (
      <>
        {renderMessageCount}
        <OverlayPanel
          dismissable={false}
          showCloseIcon={true}
          id="overlayMessages"
          ref={panelRef}
          className="p-col-8"
          onHide={() => {
            messagesRef.current.clear()
          }}
        >
          <div className="p-grid">
            <Messages onRemove={onRemoveMessage} ref={messagesRef} />
          </div>
        </OverlayPanel>
      </>
    )
  }
)

const withData = inject((stores) => ({
  merchantId: stores.MerchantsStore.selectedMerchantId,
  messages: stores.MerchantMessagesStore.messages,
  count: stores.MerchantMessagesStore.count,
  isLoading: stores.MerchantMessagesStore.isLoading,
  isError: stores.MerchantMessagesStore.isError,
  errorMsg: stores.MerchantMessagesStore.errorMsg,
  getMessages: stores.MerchantMessagesStore.getMessages,
  setMessageNotActual: stores.MerchantMessagesStore.setMessageNotActual,
  getMessagesInterval: stores.MerchantMessagesStore.getMessagesInterval,
}))

export default withData(withRouter(MerchantMessages))
