import React from "react"
import { InputText } from "primereact/inputtext"
import { Message } from "primereact/message"
import { getMessage } from "../../../services/getMessage"

const CustomInput = ({
  form,
  type,
  labelText,
  field,
  step,
  className,
  setFieldTouched,
}) => {
  const showError = !!(form.touched[field.name] && form.errors[field.name])
  return (
    <>
      <span className="p-float-label my-2">
        <InputText
          key={field.name}
          name={field.name}
          value={field.value}
          type={type}
          step={step}
          onBlur={() => form.setFieldTouched(field.name, true, true)}
          onChange={field.onChange}
          className={className + " " + (showError && "p-error")}
        />
        <label htmlFor={field.name}>{labelText}</label>
      </span>
      {showError && (
        <Message severity="error" text={getMessage(form.errors[field.name])} />
      )}
    </>
  )
}

export default CustomInput
