import React from "react"
import { inject, observer } from "mobx-react"
import { Field, Form, Formik } from "formik"
import { withRouter } from "react-router-dom"
import * as yup from "yup"
import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { Message } from "primereact/message"
import CustomInput from "../../components/common/CustomInput"
import { ErrorMsg } from "../../constants/errorMsg"
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH, PASSWORD_REGEXP } from "../../constants/user"

const schema = yup.object().shape({
  password: yup
    .string()
    .trim()
    .required(ErrorMsg.PASSWORD_REQUIRED)
    .min(MIN_PASSWORD_LENGTH, ErrorMsg.PASSWORD_NOT_VALID)
    .max(MAX_PASSWORD_LENGTH, ErrorMsg.PASSWORD_NOT_VALID)
    .matches(PASSWORD_REGEXP, ErrorMsg.PASSWORD_NOT_VALID),
  confirmPassword: yup
    .string()
    .required(ErrorMsg.CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("password")], ErrorMsg.PASSWORD_NOT_MATCH),
})

const ChangePasswordByToken = observer(({ isLoading, isError, errorMsg, changePasswordByToken, match }) => (
  <div className="p-fluid">
    <div className="p-grid p-justify-center">
      <div className="p-col-12 p-md-6 p-lg-4 mt-5">
        <Card title="Change password">
          <Formik
            initialValues={{
              password: "", confirmPassword: "",
              token: match.params.token
            }}
            enableReinitialize
            validationSchema={schema}
            onSubmit={async (values, { resetForm }) => {
              await changePasswordByToken(values)
              resetForm({})
            }}
          >
            {({ dirty }) => (
              <Form>
                <div className="p-grid">
                  {isError && errorMsg && (
                    <div className="p-col-12">
                      <Message severity="error" text={errorMsg}/>
                    </div>
                  )}
                  <div className="p-col-12">
                    <Field
                      type="password"
                      name="password"
                      labelText="New password"
                      component={CustomInput}
                    />
                  </div>
                  <div className="p-col-12">
                    <Field
                      type="password"
                      name="confirmPassword"
                      labelText="Confirm new password"
                      component={CustomInput}
                    />
                  </div>
                  <div className="p-col-12">
                    <Button
                      type="submit"
                      label="Change Password"
                      disabled={!(dirty && !isLoading)}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </div>
    </div>
  </div>
))

const withData = inject((stores) => ({
  isLoading: stores.AuthStore.isLoading,
  isError: stores.AuthStore.isError,
  errorMsg: stores.AuthStore.errorMsg,
  changePasswordByToken: stores.AuthStore.changePasswordByToken,
}))

export default withData(withRouter(ChangePasswordByToken))
