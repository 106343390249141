import { observable, action } from "mobx"
import Http from "../../../helpers/http"
import { authApi } from "../../../configs/api"
import MerchantPaymentStore from "../PaymentList/mobx"

class AddPaymentInfoStore {
  @observable isError = false
  @observable errorMsg = false
  @observable merchant = null
  @observable isLoading = true
  @observable creatingBankAccount = false
  @observable currencyList = []
  @observable msg

  @action clearMessages = () => {
    this.errorMsg = null
    this.msg = null
  }

  @action addPaymentInfo = async (paymentInfo, isStandartAccount, id) => {
    try {
      const { account, bankCode, iban, currencyId } = paymentInfo
      const reqBody = isStandartAccount
        ? { account, bankCode, id, currencyId }
        : { iban, id, currencyId }
      this.errorMsg = false
      this.isError = false
      this.creatingBankAccount = true
      const response = await Http.post(
        `${authApi}/v1/merchant/payment-info/add`,
        reqBody,
        true
      )
      if (!response.message) {
        this.msg = "Payment info added"
        MerchantPaymentStore.newPayment(response)
      } else {
        this.isError = true
        this.errorMsg = response.message
      }
      this.creatingBankAccount = false
    } catch (e) {
      this.creatingBankAccount = false
      this.isError = true
      this.errorMsg = e
    }
  }
  @action getCurrencies = async () => {
    try {
      this.errorMsg = false
      this.isError = false
      this.isLoading = true
      const response = await Http.get(
        `${authApi}/v1/currency/get-all?limit=1000`,
        true
      )
      if (!response.message) {
        this.currencyList = response.result
      } else {
        this.isError = true
        this.errorMsg = response.message
      }
      this.isLoading = false
    } catch (e) {
      this.isLoading = false
      this.isError = true
      this.errorMsg = e
    }
  }
}

export default new AddPaymentInfoStore()
