import { observable, action } from "mobx"
import Http from "../../helpers/http"
import { authApi } from "../../configs/api"

class WalletsStore {
  @observable isLoading = false
  @observable isLoadingCurrency = false
  @observable isError = false
  @observable errorMsg = null
  @observable walletsData = []
  @observable currencyList = []
  @observable actionWallet = false

  @action
  getWallets = async (isMerchant) => {
    const url = isMerchant ? `merchant/${isMerchant}` : "my"
    try {
      this.isLoading = true
      const response = await Http.get(`${authApi}/v1/wallets/${url}`, true)
      this.isLoading = false
      if (response.message) {
        this.errorMsg = `${response.message}`
        this.isError = true
      } else {
        this.walletsData = response
      }
      this.isError = false
      this.errorMsg = ""
    } catch (e) {
      this.isLoading = false
      this.errorMsg = `${e}`
    }
  }

  @action
  createWallet = async (data) => {
    try {
      this.isLoading = true
      const response = await Http.post(`${authApi}/v1/wallets/add`, data, true)
      this.actionWallet = false
      this.getWallets()
      this.isLoading = false
      if (response.message) {
        this.errorMsg = `${response.message}`
        this.isError = true
      }
      this.isError = false
      this.errorMsg = ""
    } catch (e) {
      this.isLoading = false
      this.errorMsg = `${e}`
    }
  }

  @action
  createMerchantWallet = async (data) => {
    try {
      this.isLoading = true
      const response = await Http.post(
        `${authApi}/v1/wallets/add-merchant`,
        data,
        true
      )
      this.actionWallet = false
      this.getWallets(data.merchantId)
      this.isLoading = false
      if (response.message) {
        this.errorMsg = `${response.message}`
        this.isError = true
      }
      this.isError = false
      this.errorMsg = ""
    } catch (e) {
      this.isLoading = false
      this.errorMsg = `${e}`
    }
  }

  @action
  deleteWallet = async (id, isMerchant) => {
    try {
      this.isLoading = true
      const url = isMerchant ? "delete-merchant" : "delete"
      const response = await Http.delete(
        `${authApi}/v1/wallets/${url}/${id}`,
        true
      )
      this.getWallets(isMerchant)
      this.isLoading = false
      if (response.message) {
        this.errorMsg = `${response.message}`
        this.isError = true
      }
      this.isError = false
      this.errorMsg = ""
    } catch (e) {
      this.isLoading = false
      this.errorMsg = `${e}`
    }
  }

  @action
  walletToWallet = async (data, isMerchant) => {
    try {
      this.isLoading = true
      const response = await Http.put(
        `${authApi}/v1/wallets/to-wallet`,
        data,
        true
      )
      this.getWallets(isMerchant)
      this.isLoading = false
      if (response.message) {
        this.errorMsg = `${response.message}`
        this.isError = true
      }
      this.isError = false
      this.errorMsg = ""
    } catch (e) {
      this.isLoading = false
      this.errorMsg = `${e}`
    }
  }

  @action
  getCurrency = async () => {
    try {
      this.isLoadingCurrency = true
      const response = await Http.get(`${authApi}/v1/currency/get-all`, true)
      this.isLoadingCurrency = false
      if (response.message) {
        this.errorMsg = `${response.message}`
        this.isError = true
      } else {
        this.currencyList = response.result
      }
      this.isError = false
      this.errorMsg = ""
    } catch (e) {
      this.isLoading = false
      this.errorMsg = `${e}`
    }
  }
}

export default new WalletsStore()
