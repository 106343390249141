import React, { useRef, useEffect, useState } from "react"
import { withRouter } from "react-router"
import { inject, observer } from "mobx-react"
import { Field, Form, Formik } from "formik"
import { Messages } from "primereact/messages"
import * as yup from "yup"

import CustomInput from "../../common/CustomInput"
import CustomSelectWithFilter from "../../common/CustomSelectWithFilter"

import { ErrorMsg } from "../../../constants/errorMsg"
import {
  BANK_ACCOUNT_REGEXP,
  BANK_CODE_REGEXP,
} from "../../../constants/merchant"
import { OverlayPanel } from "primereact/overlaypanel"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import validateIban from "../../../helpers/Merchant/validateIban"
import Preloader from "../../Preloader"

const AddPaymentInfo = observer(
  ({
    match,
    isLoading,
    creatingBankAccount,
    currencyList,
    errorMsg,
    msg,
    getCurrencies,
    addPaymentInfo,
    clearMessages,
  }) => {
    const id = Number(match.params.id)
    const colStyle = "p-col-12 p-md-6"

    const [isStandartAccount, changeAccountType] = useState(true)

    useEffect(() => {
      getCurrencies()
    }, [getCurrencies])

    let panelRef = useRef(null)
    let infoMessage = useRef(null)

    const schemaStandart = yup.object().shape({
      account: yup
        .string()
        .trim()
        .required(ErrorMsg.BANK_ACCOUNT_REQUIRED)
        .matches(BANK_ACCOUNT_REGEXP, ErrorMsg.BANK_ACCOUNT_NOT_VALID),
      bankCode: yup
        .string()
        .trim()
        .required(ErrorMsg.BANK_CODE_REQUIRED)
        .matches(BANK_CODE_REGEXP, ErrorMsg.BANK_CODE_NOT_VALID),
      currencyId: yup
        .number()
        .positive(ErrorMsg.CURRENCY_ID_REQUIRED)
        .required(ErrorMsg.CURRENCY_ID_REQUIRED)
        .typeError(ErrorMsg.CURRENCY_ID_REQUIRED),
    })

    const schemaIban = yup.object().shape({
      iban: yup
        .string()
        .trim()
        .test("isValidIban", ErrorMsg.IBAN_NOT_VALID, validateIban)
        .required(ErrorMsg.IBAN_REQUIRED),
      currencyId: yup
        .number()
        .positive(ErrorMsg.CURRENCY_ID_REQUIRED)
        .required(ErrorMsg.CURRENCY_ID_REQUIRED)
        .typeError(ErrorMsg.CURRENCY_ID_REQUIRED),
    })

    const inputsStandart = [
      { value: "account", label: "Bank Account" },
      { value: "bankCode", label: "Bank Code" },
    ]

    const inputIban = [{ value: "iban", label: "IBAN" }]

    const inputListRender = (isStandartAccount
      ? inputsStandart
      : inputIban
    ).map((input, i) => (
      <div key={i} className="p-col-12 p-md-6">
        <Field
          type="text"
          name={input.value}
          labelText={input.label}
          component={CustomInput}
        />
      </div>
    ))

    const selectAccountType = (
      <>
        <p className={`${colStyle} font-weight-bold`}>Account Type:</p>
        <div className={colStyle}>
          <Dropdown
            options={[
              { label: "Standart", value: true },
              { label: "IBAN", value: false },
            ]}
            value={isStandartAccount}
            onChange={(event) => {
              changeAccountType(event.value)
            }}
          ></Dropdown>
        </div>
      </>
    )

    if (msg && infoMessage.current) {
      infoMessage.current.show([
        { severity: "info", detail: msg, closable: false },
      ])
      clearMessages()
    }

    if (errorMsg && infoMessage.current) {
      infoMessage.current.show([
        { severity: "error", detail: errorMsg, closable: false },
      ])
      clearMessages()
    }
    return (
      <>
        <div className="my-4">
          <Button
            type="button"
            label="Add Payment"
            icon="pi pi-plus"
            iconPos="right"
            onClick={(e) => panelRef.current.toggle(e)}
            className="p-button button-auto-width"
          />
        </div>
        <OverlayPanel
          ref={panelRef}
          className="p-col-6"
          dismissable={false}
          showCloseIcon={true}
        >
          <div className="p-col-12">
            <Messages ref={infoMessage} />
          </div>
          {isLoading || creatingBankAccount ? (
            Preloader(
              isLoading ? "Loading currencies..." : "Creating Bank Account..."
            )
          ) : (
            <Formik
              initialValues={{
                currencyId: null,
                account: "",
                bankCode: "",
                iban: "",
              }}
              validationSchema={isStandartAccount ? schemaStandart : schemaIban}
              onSubmit={(values) =>
                addPaymentInfo(values, isStandartAccount, id)
              }
            >
              {({ dirty }) => (
                <Form>
                  <div className="p-grid pt-3">
                    {selectAccountType}
                    {inputListRender}
                    <div className="p-col-12">
                      <Field
                        type="text"
                        className="p-col-12 py-0 mb-2"
                        name={"currencyId"}
                        filterBy="name"
                        options={currencyList.map(({ name, id }) => ({
                          label: name,
                          value: id,
                        }))}
                        labelText="Select a Currency"
                        component={CustomSelectWithFilter}
                      />
                    </div>
                  </div>
                  <Button
                    type="submit"
                    label="Add payment info"
                    disabled={!(dirty && !isLoading)}
                  />
                </Form>
              )}
            </Formik>
          )}
        </OverlayPanel>
      </>
    )
  }
)

const withData = inject((stores) => ({
  isLoading: stores.AddPaymentInfoStore.isLoading,
  creatingBankAccount: stores.AddPaymentInfoStore.creatingBankAccount,
  currencyList: stores.AddPaymentInfoStore.currencyList,
  msg: stores.AddPaymentInfoStore.msg,
  errorMsg: stores.AddPaymentInfoStore.errorMsg,
  getCurrencies: stores.AddPaymentInfoStore.getCurrencies,
  addPaymentInfo: stores.AddPaymentInfoStore.addPaymentInfo,
  clearMessages: stores.AddPaymentInfoStore.clearMessages,
}))

export default withData(withRouter(AddPaymentInfo))
