import React, { useState, useEffect } from "react"
import { inject, observer } from "mobx-react"
import * as yup from "yup"

import { Dialog } from "primereact/dialog"
import { Field, Form, Formik } from "formik"
import { Card } from "primereact/card"
import { ProgressSpinner } from "primereact/progressspinner"
import { Button } from "primereact/button"

import CustomInput from "../../components/common/CustomInput"
import CustomSelect from "../../components/common/CustomSelect"
import { ErrorMsg } from "../../constants/errorMsg"
import {
  PHONE_REGEXP,
  REG_NUMBER_REGEXP,
  TAX_ID_REGEXP,
  COMPANY_NAME_MIN,
  COMPANY_NAME_MAX,
  COMPANY_ADDRESS_MIN,
  COMPANY_ADDRESS_MAX,
  COMPANY_ALIAS_MIN,
  COMPANY_ALIAS_MAX,
} from "../../constants/merchant"

const CreateMerchant = observer(
  ({
    isLoading,
    countriesList,
    tryCreateMerchant,
    getCountries,
    isCreated,
    errorMsg,
  }) => {
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
      getCountries()
    }, [getCountries])

    const schema = yup.object().shape({
      name: yup
        .string()
        .min(COMPANY_NAME_MIN, ErrorMsg.COMPANY_NAME_NOT_VALID)
        .max(COMPANY_NAME_MAX, ErrorMsg.COMPANY_NAME_NOT_VALID)
        .required(ErrorMsg.COMPANY_NAME_REQUIRED),
      alias: yup
        .string()
        .min(COMPANY_ALIAS_MIN, ErrorMsg.COMPANY_ALIAS_NOT_VALID)
        .max(COMPANY_ALIAS_MAX, ErrorMsg.COMPANY_ALIAS_NOT_VALID)
        .required(ErrorMsg.COMPANY_ALIAS_REQUIRED),
      address: yup
        .string()
        .min(COMPANY_ADDRESS_MIN, ErrorMsg.ADDRESS_NOT_VALID)
        .max(COMPANY_ADDRESS_MAX, ErrorMsg.ADDRESS_NOT_VALID)
        .required(ErrorMsg.ADDRESS_REQUIRED),
      email: yup
        .string()
        .trim()
        .email(ErrorMsg.EMAIL_NOT_VALID)
        .required(ErrorMsg.EMAIL_REQUIRED),
      phone: yup
        .string()
        .trim()
        .required(ErrorMsg.PHONE_REQUIRED)
        .matches(PHONE_REGEXP, ErrorMsg.PHONE_NOT_VALID),
      regNumber: yup
        .string()
        .trim()
        .required(ErrorMsg.REG_NUMBER_REQUIRED)
        .matches(REG_NUMBER_REGEXP, ErrorMsg.REG_NUMBER_NOT_VALID),
      taxId: yup
        .string()
        .trim()
        .required(ErrorMsg.TAX_ID_REQUIRED)
        .matches(TAX_ID_REGEXP, ErrorMsg.TAX_ID_NOT_VALID),
      countryId: yup
        .number()
        .typeError(ErrorMsg.COUTRY_ID_REQUIRED)
        .positive(ErrorMsg.COUTRY_ID_REQUIRED),
    })

    const inputList = [
      { value: "name", label: "Company Name" },
      { value: "alias", label: "Company Alias" },
      { value: "address", label: "Address" },
      { value: "email", label: "Email" },
      { value: "phone", label: "Phone" },
      { value: "regNumber", label: "Registration Number" },
      { value: "taxId", label: "Tax ID" },
    ]

    const inputListRender = inputList.map((input) => {
      return (
        <div className="my-4">
          <Field
            type="text"
            className="p-col-12"
            name={input.value}
            labelText={input.label}
            component={CustomInput}
          />
        </div>
      )
    })

    const dialogContent = (
      <div className="p-grid p-dir-col p-align-center p-justify-center">
        {isLoading ? (
          <>
            <ProgressSpinner className="p-col" />
            <p className="p-col">Loading...</p>
          </>
        ) : (
          <p className="p-col">{isCreated ? "Merchant Created" : errorMsg}</p>
        )}
      </div>
    )
    return (
      <div className="p-grid p-align-center p-justify-center">
        <div className="p-col-10 p-sm-9 p-md-7 p-lg-5">
          <Card title="Create merchant">
            <Dialog
              className="my-10"
              baseZIndex={1100}
              header={"Creating Merchant"}
              visible={showModal}
              closable={!isLoading}
              style={{ width: "50vw" }}
              modal={true}
              onHide={() => setShowModal(false)}
            >
              {dialogContent}
            </Dialog>

            <Formik
              initialValues={{
                name: "",
                alias: "",
                email: "",
                phone: "",
                regNumber: "",
                taxId: "",
                address: "",
                countryId: 0,
              }}
              enableReinitialize={true}
              validationSchema={schema}
              onSubmit={async (values, { setSubmitting }) => {
                setShowModal(true)
                tryCreateMerchant(values)
                setSubmitting(false)
              }}
            >
              {({ dirty, isValidating }) => (
                <Form>
                  {inputListRender}
                  <div className="my-4">
                    <Field
                      type="text"
                      className="p-col-12 py-0 mb-2"
                      name={"countryId"}
                      options={countriesList.map(({ name, id }) => ({
                        label: name,
                        value: id,
                      }))}
                      labelText="Select a Country"
                      component={CustomSelect}
                    />
                  </div>
                  <Button
                    type="submit"
                    label="Create Merchant"
                    disabled={!(dirty && !isLoading)}
                  />
                </Form>
              )}
            </Formik>
          </Card>
        </div>
      </div>
    )
  }
)
const withData = inject((stores) => ({
  isError: stores.CreateMerchantStore.isError,
  errorMsg: stores.CreateMerchantStore.errorMsg,
  isLoading: stores.CreateMerchantStore.isLoading,
  countriesList: stores.CreateMerchantStore.countriesList,
  isCreated: stores.CreateMerchantStore.isCreated,
  getCountries: stores.CreateMerchantStore.getCountries,
  tryCreateMerchant: stores.CreateMerchantStore.tryCreateMerchant,
}))
export default withData(CreateMerchant)
