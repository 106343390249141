import React, { useState, useEffect } from "react"
import { withRouter } from "react-router"
import { observer, inject } from "mobx-react"
import moment from "moment"
import { Panel } from "primereact/panel"
import Preloader from "../../Preloader"

const MerchantInDetails = observer(
  ({ match, merchant, isError, errorMsg, getMerchant }) => {
    const [panelAboutCollapsed, setPanelAboutCollapsed] = useState(false)
    const id = Number(match.params.id)
    useEffect(() => {
      getMerchant(id)
    }, [getMerchant, id])

    const colStyle = "p-col-12 p-md-6 p-sm-6 p-lg-3"

    const statuses = [
      "Registred",
      "Payment info added",
      "Documents uploaded",
      "Counterparty Verification",
      "Test",
      "The contract is signed",
    ]
    const renderAbout = merchant
      ? [
          { text: "Company name", value: merchant.name },
          { text: "Company id", value: merchant.id },
          { text: "Alias", value: merchant.alias },
          { text: "Phone", value: merchant.phone },
          { text: "Address", value: merchant.address },
          { text: "Email", value: merchant.email },
          { text: "Tax ID", value: merchant.taxId },
          { text: "Registration Number", value: merchant.regNumber },
          { text: "Status", value: statuses[merchant.status] },
          { text: "Approved", value: merchant.approved ? "Yes" : "No" },
          { text: "Blocked", value: merchant.onHold ? "Yes" : "No" },
          {
            text: "Created",
            value: moment(merchant.createdAt).format("DD/MM/YYYY HH:mm:ss"),
          },
          {
            text: "Updated",
            value: moment(merchant.updatedAt).format("DD/MM/YYYY HH:mm:ss"),
          },
        ].map((field) => (
          <>
            <p className={colStyle}>
              <b>{field.text}:</b>
            </p>
            <p className={colStyle} style={{ wordWrap: "break-word" }}>
              {field.value}
            </p>
          </>
        ))
      : Preloader("Loading...")

    const panelAbout = (
      <Panel
        className="mt-3"
        header="About merchant"
        toggleable={true}
        collapsed={panelAboutCollapsed}
        onToggle={(e) => setPanelAboutCollapsed(e.value)}
      >
        <div className="p-grid">{isError ? errorMsg : renderAbout}</div>
      </Panel>
    )

    return <>{panelAbout}</>
  }
)

const withData = inject((stores) => ({
  id: stores.MerchantsStore.selectedMerchantId,
  merchant: stores.MerchantInfoStore.merchant,
  getMerchant: stores.MerchantInfoStore.getMerchant,
  isError: stores.MerchantInfoStore.isError,
  errorMsg: stores.MerchantInfoStore.errorMsg,
}))

export default withData(withRouter(MerchantInDetails))
