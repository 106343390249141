import React, { useEffect, useRef } from "react"
import { withRouter } from "react-router"
import { OverlayPanel } from "primereact/overlaypanel"
import { Messages } from "primereact/messages"
import { inject, observer } from "mobx-react"
import * as yup from "yup"
import { Field, Form, Formik } from "formik"
import { Button } from "primereact/button"
import { Checkbox } from "primereact/checkbox"
import { Dropdown } from "primereact/dropdown"
import CustomInput from "../../common/CustomInput"
import { ErrorMsg } from "../../../constants/errorMsg"
import Preloader from "../../Preloader"

const AddPaymentInfo = observer(
  ({
    match,
    isLoading,
    creatingUser,
    errorMsg,
    msg,
    templates,
    selectedTemplateIndex,
    templatePermissions,
    handleTemplateSelection,
    getTemplates,
    createMerchantUser,
    clearMessages,
  }) => {
    useEffect(() => {
      getTemplates()
    }, [getTemplates])
    const id = Number(match.params.id)
    const colStyle = "p-col-12 p-md-6"

    let panelRef = useRef(null)
    let infoMessage = useRef(null)

    const schema = yup.object().shape({
      email: yup
        .string()
        .trim()
        .email(ErrorMsg.EMAIL_NOT_VALID)
        .required(ErrorMsg.EMAIL_REQUIRED),
    })

    const chexBoxComponent = ({ labelText, field }) => {
      return (
        <div>
          <Checkbox
            key={field.name}
            name={field.name}
            value={field.value}
            checked={field.value}
            onChange={field.onChange}
          ></Checkbox>
          <label htmlFor={field.name} className="p-checkbox-label">
            {labelText}
          </label>
        </div>
      )
    }

    const checkboxList = [
      { value: "manageUsers", label: "Manage Users" },
      { value: "managePayment", label: "Add Payment Info" },
      { value: "uploadDocuments", label: "Upload Documents" },
    ]

    const renderCustomCheckBoxes = checkboxList.map((checkBox, i) => (
      <div key={i} className={colStyle}>
        <Field
          type="checkbox"
          name={checkBox.value}
          labelText={checkBox.label}
          component={chexBoxComponent}
        />
      </div>
    ))

    const selectTemplate = (
      <>
        <p className={`${colStyle} font-weight-bold`}>Use Template:</p>
        <div className={colStyle}>
          <Dropdown
            options={[
              { label: "Dont Use Template", value: null },
              ...templates.map((template, i) => ({
                label: template.name,
                value: i,
              })),
            ]}
            value={selectedTemplateIndex}
            onChange={(event) => {
              handleTemplateSelection(event.value)
            }}
          ></Dropdown>
        </div>
      </>
    )

    const renderTemplateCheckBoxes = checkboxList.map((checkBox, i) => (
      <div key={i} className={colStyle}>
        <Checkbox
          name={checkBox.value}
          value={templatePermissions[checkBox.value]}
          checked={templatePermissions[checkBox.value]}
          disabled={true}
        ></Checkbox>
        <label htmlFor={checkBox.value} className="p-checkbox-label">
          {checkBox.label}
        </label>
      </div>
    ))

    const renderEmail = (
      <>
        <p className={`${colStyle} font-weight-bold`}>User Email:</p>
        <div key={100} className={colStyle}>
          <Field
            type="text"
            name="email"
            labelText="User Email"
            component={CustomInput}
          />
        </div>
      </>
    )

    if (msg) {
      infoMessage.current.show([
        { severity: "info", detail: msg, closable: false },
      ])
      clearMessages()
    }

    if (errorMsg) {
      infoMessage.current.show([
        { severity: "error", detail: errorMsg, closable: false },
      ])
      clearMessages()
    }
    return (
      <>
        <div className="my-4">
          <Button
            type="button"
            label="Add User"
            icon="pi pi-plus"
            iconPos="right"
            onClick={(e) => panelRef.current.toggle(e)}
            className="p-button button-auto-width"
          />
        </div>
        <OverlayPanel
          ref={panelRef}
          dismissable={false}
          showCloseIcon={true}
          className="p-col-6"
        >
          {isLoading || creatingUser ? (
            Preloader(
              isLoading ? "Loading templates ..." : "Creating new user ..."
            )
          ) : (
            <Formik
              initialValues={{
                email: "",
                manageUsers: false,
                managePayment: false,
                uploadDocuments: false,
              }}
              enableReinitialize
              validationSchema={schema}
              onSubmit={(values) => createMerchantUser(values, id)}
            >
              {({ dirty }) => (
                <Form>
                  <div className="p-grid">
                    <div className="p-col-12">
                      <Messages ref={infoMessage} />
                    </div>
                    {selectTemplate}
                    {renderEmail}
                    {selectedTemplateIndex !== null
                      ? renderTemplateCheckBoxes
                      : renderCustomCheckBoxes}
                    <Button
                      type="submit"
                      label="Add New User"
                      disabled={!(dirty && !isLoading)}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </OverlayPanel>
      </>
    )
  }
)

const withData = inject((stores) => ({
  id: stores.MerchantsStore.selectedMerchantId,
  isLoading: stores.AddMerchantUserStore.isLoading,
  creatingUser: stores.AddMerchantUserStore.creatingUser,
  isError: stores.AddMerchantUserStore.isError,
  msg: stores.AddMerchantUserStore.msg,
  errorMsg: stores.AddMerchantUserStore.errorMsg,
  templates: stores.AddMerchantUserStore.templates,
  selectedTemplateIndex: stores.AddMerchantUserStore.selectedTemplateIndex,
  templatePermissions: stores.AddMerchantUserStore.templatePermissions,
  handleTemplateSelection: stores.AddMerchantUserStore.handleTemplateSelection,
  getTemplates: stores.AddMerchantUserStore.getTemplates,
  createMerchantUser: stores.AddMerchantUserStore.createMerchantUser,
  clearMessages: stores.AddMerchantUserStore.clearMessages,
}))

export default withData(withRouter(AddPaymentInfo))
