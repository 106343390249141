import React, { useState } from "react"
import { TabMenu } from "primereact/tabmenu"
import MerchantInfo from "../../components/Merchants/MerchantInfo"
import AddPaymentInfo from "../../components/Merchants/AddPaymentInfo"
import AddMerchantUser from "../../components/Merchants/AddMerchantUser"
import MerchantUsers from "../../components/Merchants/MerchantUsers"
import MerchantMessages from "../../components/Merchants/MerchantMessages"
import PaymentList from "../../components/Merchants/PaymentList"
import Wallets from "../../components/Wallets"
import MerchantsDocuments from "../../components/Merchants/MerchantsDocuments"
import AddProduct from "../../components/Merchants/AddProduct"
import ProductsList from "../../components/Merchants/ProductsList"

export default () => {
  const [items] = useState([
    { label: "Info", icon: "pi pi-fw pi-info-circle" },
    { label: "Payment", icon: "pi pi-fw pi-dollar" },
    { label: "Wallets", icon: "pi pi-fw pi-money-bill" },
    { label: "Users", icon: "pi pi-fw pi-users" },
    { label: "Products", icon: "pi pi-fw pi-shopping-cart" },
  ])
  const [activeTab, setActiveTab] = useState(items[0])

  const renderConten = ({ label }) => {
    switch (label) {
      case "Info":
        return (
          <>
            <MerchantInfo />
            <MerchantsDocuments />
          </>
        )

      case "Payment":
        return (
          <>
            <AddPaymentInfo />
            <PaymentList />
          </>
        )
      case "Wallets":
        return (
          <>
            <Wallets />
          </>
        )
      case "Users":
        return (
          <>
            <AddMerchantUser />
            <MerchantUsers />
          </>
        )
      case "Products":
        return (
          <>
            <AddProduct />
            <ProductsList />
          </>
        )

      default:
        break
    }
  }

  return (
    <>
      <TabMenu
        model={items}
        activeItem={activeTab}
        onTabChange={({ value }) => {
          setActiveTab(value)
        }}
      />
      <MerchantMessages />
      {renderConten(activeTab)}
    </>
  )
}
