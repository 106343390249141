import { action, observable } from "mobx"
import Http from "../../helpers/http"
import { authApi } from "../../configs/api"

class CreditsCardsStore {
  @observable isLoading = false
  @observable isError = false
  @observable errorMsg = null
  @observable creditCardsList = null
  @observable stripeSecret = null

  @action
  getCreditCards = async () => {
    try {
      this.isLoading = true
      const response = await Http.get(`${authApi}/v1/stripe/card/list`, true)
      if (response.message) {
        this.isError = true
        this.errorMsg = response.message
      } else {
        this.creditCardsList = response.result
        this.isError = false
        this.errorMsg = null
      }
      this.isLoading = false
    } catch (e) {
      this.isLoading = false
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  createCardIntent = async () => {
    try {
      const response = await Http.get(`${authApi}/v1/stripe/card/add`, true)
      if (response.message) {
        this.isError = true
        this.errorMsg = response.message
        this.stripeSecret = null
      } else {
        this.isError = false
        this.errorMsg = null
        this.stripeSecret = response.clientSecret
      }
    } catch (e) {
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  deleteCard = async (card) => {
    try {
      const response = await Http.delete(
        `${authApi}/v1/stripe/card/${card.id}`,
        true
      )
      if (response && response.message) {
        this.isError = true
        this.errorMsg = response.message
      } else {
        this.isError = false
        this.errorMsg = null
        this.getCreditCards()
      }
    } catch (e) {
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  createPayment = async (wallet, amount, currency, paymentMethodId) => {
    try {
      this.isLoading = true
      const response = await Http.post(
        `${authApi}/v1/stripe/payment`,
        { wallet, amount, currency: currency, paymentMethodId },
        true
      )
      if (response.message) {
        this.isError = true
        this.errorMsg = response.message.raw ? response.message.raw.message : response.message
        this.stripeSecret = null
      } else {
        this.isError = false
        this.errorMsg = null
        this.stripeSecret = response.clientSecret
        return response
      }
      this.isLoading = false
    } catch (e) {
      this.isError = true
      this.errorMsg = e
      this.isLoading = false
    }
  }
}

export default new CreditsCardsStore()
