import React, { useEffect } from "react"
import { withRouter } from "react-router"
import { observer, inject } from "mobx-react"
import moment from "moment"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

import Preloader from "../../Preloader"

const ProductsList = observer(
  ({ match, isLoading, paymentList, getPaymentAndCurrencies }) => {
    const merchantId = Number(match.params.id)
    useEffect(() => {
      getPaymentAndCurrencies(merchantId)
    }, [getPaymentAndCurrencies, merchantId])

    const formattedDate = (rowData) => {
      return <>{moment(rowData.createdAt).format("DD/MM/YYYY HH:mm:ss")}</>
    }

    return (
      <>
        {isLoading ? (
          Preloader("Loading payment info...")
        ) : (
          <DataTable
            emptyMessage="Payment details not found"
            className="mt-2"
            value={paymentList}
            dataKey="id"
          >
            <Column key="id" field="id" header="ID" />
            <Column key="iban" field="iban" header="IBAN" />
            <Column key="account" field="account" header="Account" />
            <Column key="bankCode" field="bankCode" header="Bank Code" />
            <Column key="currencyName" field="currencyName" header="Currency" />
            <Column
              key="currencyCodeNumber"
              field="currencyCodeNumber"
              header="Currency code"
            />
            <Column
              key="createdAt"
              field="createdAt"
              header="Date"
              body={formattedDate}
            />
          </DataTable>
        )}
      </>
    )
  }
)

const withData = inject((stores) => ({
  merchantId: stores.MerchantsStore.selectedMerchantId,
  isLoading: stores.MerchantPaymentStore.isLoading,
  paymentList: stores.MerchantPaymentStore.paymentList,
  getPaymentAndCurrencies: stores.MerchantPaymentStore.getPaymentAndCurrencies,
}))

export default withData(withRouter(ProductsList))
