import React, { useState } from "react"
import { inject, observer } from "mobx-react"
import classNames from "classnames"

import StripePayment from "../../Stripe/StripePayment"
import WayForPayBalance from "../../WayForPay/balance"
import LiqPay from "../../LiqPay"

import { Dialog } from "primereact/dialog"
import { Card } from "primereact/card"
import { Message } from "primereact/message"
import { InputNumber } from "primereact/inputnumber"

import logo2CO from "../../../images/2checkout.jpg"
import logoLiqpay from "../../../images/liqpay.jpg"
import logoStripe from "../../../images/stripe.jpg"
import logoWayForPay from "../../../images/way4pay.jpg"

import "./WalletsBalance.scss"

const WalletsBalance = observer(({ wallet, currency, isMerchant, isOpen, closeAction, isError, errorMsg }) => {
  const [amount, setAmount] = useState(0)
  const [payment, setPayment] = useState(null)

  const paymentSystems = [{
    name: "2CO",
    icon: logo2CO
  }, {
    name: "Liqpay",
    icon: logoLiqpay
  }, {
    name: "Stripe",
    icon: logoStripe
  }, {
    name: "WayForPay",
    icon: logoWayForPay
  }]

  const handlePayment = paymentItem => {
    if (!amount || !currency) {
      return
    }
    setPayment(paymentItem.name)
  }

  const generatePaymentSystem = data => (
    data.map((item, key) => (
      <div className={classNames("payment__item", {"active": payment === item.name})}>
        <img
          key={key}
          src={item.icon}
          alt={item.name}
          onClick={() => handlePayment(item)}
          className="payment__img"
        />
      </div>
    ))
  )

  return (
    <Dialog
      header="You can add funds to your account by next methods"
      visible={isOpen}
      modal
      onHide={closeAction}
      className="wallet-balance popup-width"
      baseZIndex={9999}
    >
      <div className="p-grid">
        <div className="p-col-12">
          <Card title={` Set amount in ${currency}`}>
            {isError && errorMsg && (
              <div className="p-col-12">
                <Message severity="error" text={errorMsg} className="p-flex"/>
              </div>
            )}
            <div className="p-col-12">
              <div className="p-float-label">
                <InputNumber
                  id="name"
                  step={0.01}
                  value={amount}
                  onChange={e => setAmount(e.target.value)}
                  className="p-flex"
                />
                <label htmlFor="name">Cash amount</label>
              </div>
            </div>
          </Card>
        </div>
        <div className="p-col-12">
          <Card title="Choose system">
            <div className="payment">
              {generatePaymentSystem(paymentSystems)}
            </div>
            {payment === "Stripe" && (
              <StripePayment
                wallet={wallet}
                amount={amount}
                currency={currency}
                isMerchant={isMerchant}
                closeAction={closeAction}
              />
            )}
            {payment === "WayForPay" && (
              <WayForPayBalance
                wallet={wallet}
                amount={amount}
                currency={currency}
              />
            )}
            {payment === "Liqpay" && (
              <LiqPay
                wallet={wallet}
                amount={amount}
                currency={currency}
              />
            )}
          </Card>
        </div>
      </div>
    </Dialog>
  )
})

const withData = inject(store => ({
  isError: store.WalletsStore.isError,
  errorMsg: store.WalletsStore.errorMsg,
}))

export default withData(WalletsBalance)
