import React, { useEffect } from "react"
import { inject, observer } from "mobx-react"
import { Field, Form, Formik } from "formik"
import { Button } from "primereact/button"
import { Message } from "primereact/message"
import CustomSelect from "../common/CustomSelect"

const StripePayment = observer((
  {
    wallet,
    amount,
    currency,
    isMerchant,
    createPayment,
    errorMsg,
    isLoading,
    getCreditCards,
    creditCardsList,
    getWallets,
    closeAction
  }) => {
    useEffect(() => {
      getCreditCards()
    }, [getCreditCards])

    const handleSubmit = async (values, { setSubmitting, setErrors, resetForm }) => {
      try {
        const response = await createPayment(wallet, amount, currency, values.paymentMethodId)
        if (response) {
          resetForm({})
          getWallets(isMerchant)
          closeAction()
        }
      } catch (error) {
        setSubmitting(false)
        setErrors({ submit: error.message })
      }
    }

    const cardTemplate = (option) => {
      return <>**** **** **** {option.card.last4}</>
    }

    return (
      <Formik
        initialValues={{ paymentMethodId: null }}
        onSubmit={handleSubmit}
      >
        {({ dirty }) => (
          <Form>
            <div className="p-grid p-fluid mt-3">
              {errorMsg && (
                <div className="p-col-12">
                  <Message severity="error" text={errorMsg}/>
                </div>
              )}
              {creditCardsList && creditCardsList.length > 0 ? (
                <>
                  <div className="p-col-12">
                    <Field
                      name="paymentMethodId"
                      labelText="Card"
                      options={creditCardsList}
                      component={CustomSelect}
                      optionLabel="card.last4"
                      optionValue="id"
                      itemTemplate={cardTemplate}
                    />
                  </div>
                  <div className="p-col-12">
                    <Button
                      label="Pay"
                      disabled={!(dirty && !isLoading)}
                    />
                  </div>
                </>
              ) : (
                <div className="p-col-12">
                  <p>User doesn't have any cards in stripe. Please add one to continue.</p>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    )
  }
)

const withData = inject((stores) => ({
  getCreditCards: stores.StripeStore.getCreditCards,
  errorMsg: stores.StripeStore.errorMsg,
  isLoading: stores.StripeStore.isLoading,
  creditCardsList: stores.StripeStore.creditCardsList,
  createPayment: stores.StripeStore.createPayment,
  stripeSecret: stores.StripeStore.stripeSecret,
  getWallets: stores.WalletsStore.getWallets,
}))

export default withData(StripePayment)
