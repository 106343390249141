import React, { useState } from "react"
import { inject, observer } from "mobx-react"
import classNames from "classnames"

import { Card } from "primereact/card"
import { Dropdown } from "primereact/dropdown"
import { Message } from "primereact/message"
import { InputNumber } from "primereact/inputnumber"
import { TabPanel, TabView } from "primereact/tabview"
import {InputSwitch} from "primereact/inputswitch"
import { Button } from "primereact/button"

import logo2CO from "../../../images/2checkout.jpg"
import logoLiqpay from "../../../images/liqpay.jpg"
import logoStripe from "../../../images/stripe.jpg"
import logoWayForPay from "../../../images/way4pay.jpg"

import "./WalletsBalance.scss"


const WalletsRemittance = observer(
  ({
     wallet,
     commission,
     isMerchant,
     close,
     currency,
     isError,
     errorMsg,
     walletToWallet,
     walletsData
   }) => {
  const [amount, setAmount] = useState(0)
  const [payment, setPayment] = useState(null)
  const [activeTab, setActiveTab] = useState(0)
  const [transferToWallet, setTransferToWallet] = useState(true)
  const [walletTo, setWalletTo] = useState("")

  const paymentSystems = [{
    name: "2CO",
    icon: logo2CO
  }, {
    name: "Liqpay",
    icon: logoLiqpay
  }, {
    name: "Stripe",
    icon: logoStripe
  }, {
    name: "WayForPay",
    icon: logoWayForPay
  }]

  const handlePayment = paymentItem => {
    if (!amount || !currency) {
      return
    }
    setPayment(paymentItem.name)
  }

  const generatePaymentSystem = data => (
    data.map((item, key) => (
      <div className={classNames("payment__item", {"active": payment === item.name})}>
        <img
          key={key}
          src={item.icon}
          alt={item.name}
          onClick={() => handlePayment(item)}
          className="payment__img"
        />
      </div>
    ))
  )

  const submitTransfer = () => {
    walletToWallet({
      amount: Number(amount),
      walletFrom: wallet,
      walletTo: transferToWallet ? walletTo.id : walletTo
    }, isMerchant)
    setAmount(0)

    setWalletTo("")
    close()
  }

  const parseBalance = sum => ((sum / 100).toFixed(2))

  return (
    <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
      <TabPanel header="Transfer to wallet">
        <div className="p-grid">
          <div className="p-col-12 mt-3">
            <div className="p-float-label">
              <InputNumber
                id="name"
                step={0.01}
                value={amount}
                type="text"
                onChange={e => setAmount(e.target.value)}
                className="p-flex"
              />
              <label htmlFor="name">Cash amount</label>
            </div>
          </div>
          <div className="p-col-12">
            <div className="p-float-label">
              <span>Transaction commission: {parseBalance(commission * amount)}</span>
            </div>
          </div>
          <div className="p-col-12">
            <div className="p-align-center p-flex">
              <InputSwitch checked={transferToWallet} onChange={e => {
                setTransferToWallet(e.value)
                setWalletTo("")
              }}/>
              <span className="ml-2">Transfer to your wallet</span>
            </div>
          </div>
          <div className="p-col-12">
            <div className="p-float-label">
              {transferToWallet ? (
                <Dropdown
                  value={walletTo}
                  options={walletsData.filter(item => item.id !== wallet)}
                  optionLabel="name"
                  editable={false}
                  onChange={e => setWalletTo(e.value)}
                  placeholder="Select a wallet"
                  className="p-flex"
                />
              ) : (
                <>
                  <InputNumber
                    id="walletTo"
                    value={walletTo}
                    onChange={e => setWalletTo(e.target.value)}
                    className="p-flex"
                  />
                  <label htmlFor="walletTo">Enter wallet id</label>
                </>
              )}
            </div>
          </div>

          <div className="p-col-12">
            <Button
              type="submit"
              label="Submit"
              onClick={submitTransfer}
            />
          </div>
        </div>
      </TabPanel>



      <TabPanel header="Transfer to credit card">
        <div className="p-grid">
          <div className="p-col-12">
            <Card title="Set amount">
              {isError && errorMsg && (
                <div className="p-col-12">
                  <Message severity="error" text={errorMsg} className="p-flex"/>
                </div>
              )}
              <div className="p-col-12">
                <div className="p-float-label">
                  <InputNumber
                    id="name"
                    step={0.01}
                    value={amount}
                    onChange={e => setAmount(e.target.value)}
                    className="p-flex"
                  />
                  <label htmlFor="name">Cash amount</label>
                </div>
              </div>
            </Card>
          </div>

          <div className="p-col-12">
            <Card title="Choose system">
              <div className="payment">
                {generatePaymentSystem(paymentSystems)}
              </div>
              {payment === "Stripe" && (
                <></> // TODO: here is the component of stripe
              )}
              {payment === "WayForPay" && (
                <></> // TODO: here is the component of way4pay
              )}
            </Card>
          </div>
        </div>

      </TabPanel>
    </TabView>
  )
})

const withData = inject(store => ({
  currencyList: store.WalletsStore.currencyList,
  isError: store.WalletsStore.isError,
  errorMsg: store.WalletsStore.errorMsg,
  walletToWallet: store.WalletsStore.walletToWallet,
  walletsData: store.WalletsStore.walletsData,
}))

export default withData(WalletsRemittance)
