import { observable, action } from "mobx"
import Http from "../../../helpers/http"
import { authApi } from "../../../configs/api"

class MerchantPaymentStore {
  @observable isLoading = true
  @observable isError = false
  @observable errorMsg = false
  @observable paymentList = []

  @action newPayment = async (newPayment) => {
    const currency = await Http.get(
      `${authApi}/v1/currency/get/${newPayment.currencyId}`,
      true
    )
    newPayment.currencyName = currency.name
    newPayment.currencyCodeNumber = currency.codeNumber

    this.paymentList = [...this.paymentList, newPayment]
  }

  @action getPaymentAndCurrencies = async (merchantId) => {
    try {
      this.isLoading = true
      this.errorMsg = false
      this.isError = false
      const response = await Promise.all([
        Http.get(
          `${authApi}/v1/merchant/payment-info/get-all/${merchantId}`,
          true
        ),
        Http.get(`${authApi}/v1/currency/get-all?limit=1000`, true),
      ])
      if (!response[0]["message"] && !response[1]["message"]) {
        const paymentList = response[0]["result"]
        const currencyList = response[1]["result"]
        paymentList.forEach((payment) => {
          const currency = currencyList.find(
            ({ id }) => id === payment.currencyId
          )
          payment.currencyName = currency ? currency.name : "not found"
          payment.currencyCodeNumber = currency
            ? currency.codeNumber
            : "not found"
        })
        this.paymentList = paymentList
        this.isError = false
        this.errorMsg = null
      } else {
        this.isError = true
        this.errorMsg = response.message
      }
      this.isLoading = false
    } catch (e) {
      this.isError = true
      this.errorMsg = e
      this.isLoading = false
    }
  }
}

export default new MerchantPaymentStore()
