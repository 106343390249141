import React, {useState, useEffect, useRef} from "react"
import { inject, observer } from "mobx-react"

import { RiDeleteBinLine } from "react-icons/ri"
import { Button } from "primereact/button"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import Frame from "react-frame-component"

import "react-perfect-scrollbar/dist/css/styles.css"

const WayForPayCards = observer(
  ({
    creditCardsList,
    isLoading,
    getCreditCards,
    deleteCard,
    getCardForm,
    cardForm,
  }) => {
    const [showForm, setShowForm] = useState(false)

    const dt = useRef(null)
    const hideIframe = (e) => {
      if (e.target.className === "iframe-close") {
        getCardForm()
        setShowForm(false)
      }
    }

    useEffect(() => {
      getCreditCards()
      getCardForm()
    }, [getCreditCards, getCardForm])

    const submitCardForm = () => {
      document
        .getElementById("card-iframe")
        .contentWindow.document.getElementById("wfp-add-card-form")
        .submit()
    }

    const generateSettingButton = rowData => {
      return <RiDeleteBinLine onClick={() => deleteCard(rowData.id)} />
    }

    return (
      <>
        <div className="ccard-list">
          <div className="p-grid">
            <div className="p-col-12">
              <Button
                className="p-button button-auto-width mb-1"
                onClick={() => setShowForm(true)}
                label=" Add card"
              />
              <Button
                className="p-button button-auto-width mb-1"
                onClick={getCreditCards}
                label="Refresh"
              />
            </div>
          </div>
          <div className="p-grid">
            <div className="p-col">
              <DataTable
                ref={dt}
                value={creditCardsList}
                responsive
                className="p-datatable-customers"
                dataKey="id"
                rowHover={false}
                paginator={false}
                emptyMessage="No cards found"
                loading={isLoading}
              >
                <Column field="cardPan" header="Card pad" />
                <Column field="id" header="Delete card" body={rowData => generateSettingButton(rowData)} />
              </DataTable>
            </div>
          </div>
        </div>

        {showForm ? (
          <div className="iframe-close" onClick={hideIframe}>
            <div className="iframe-wrapper">
              <Frame
                contentDidMount={submitCardForm}
                id="card-iframe"
                width="500"
                height="500"
              >
                <span dangerouslySetInnerHTML={{ __html: cardForm }} />
              </Frame>
            </div>
          </div>
        ) : null}
      </>
    )
  }
)

const withData = inject((stores) => ({
  creditCardsList: stores.WayForPayStore.creditCardsList,
  isLoading: stores.WayForPayStore.isLoading,
  getCreditCards: stores.WayForPayStore.getCreditCards,
  deleteCard: stores.WayForPayStore.deleteCard,
  getCardForm: stores.WayForPayStore.getCardForm,
  cardForm: stores.WayForPayStore.cardForm,
}))

export default withData(WayForPayCards)
