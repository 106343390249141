import {authApi} from "../configs/api";

export default class Http {
  static HEADERS = {
    "Content-Type": "application/json",
  }

  static async get(url, useToken = false, isBlob = false) {
    try {
      return await request(url, "GET", null, useToken, isBlob)
    } catch (e) {
      return e
    }
  }

  static async post(url, data = {}, useToken = false) {
    try {
      return await request(url, "POST", data, useToken)
    } catch (e) {
      return e
    }
  }

  static async put(url, data = {}, useToken = false) {
    try {
      return await request(url, "PUT", data, useToken)
    } catch (e) {
      return e
    }
  }

  static async delete(url, useToken = false) {
    try {
      return await request(url, "DELETE", null, useToken)
    } catch (e) {
      return e
    }
  }
}

async function request(url, method = "GET", data, useToken = false, isBlob = false) {
  const token = localStorage.getItem("token")
  const config = {
    method,
    headers: Http.HEADERS,
  }

  if (useToken && localStorage.getItem("token")) {
    config.headers.Authorization = token
  }

  if (method === "POST" || method === "PUT") {
    config.body = JSON.stringify(data)
  }

  try {
    const response = await fetch(url, config)
    if (response.ok) {
      if (response.status === 204) {
        return Promise.resolve()
      }
      if (isBlob) {
        return await response.blob()
      } else {
        return await response.json()
      }
    } else {
      if (response.status === 401 && token && localStorage.getItem("refreshToken")) {
        const refreshData = await refreshTokenRequest()
        if (!refreshData.message) {
          localStorage.setItem("token", refreshData.token)
          localStorage.setItem("refreshToken", refreshData.refreshToken)
          config.headers.Authorization = refreshData.token
          return fetch(url, config).then(response => response.json())
        }
        localStorage.removeItem("token")
        localStorage.removeItem("refreshToken")
        document.location.href = '/';
      }
      return response.json()
    }
  } catch (e) {
    return Promise.reject(e)
  }
}

async function refreshTokenRequest() {
  const refreshToken = localStorage.getItem("refreshToken")
  const configRefresh = {
    method: "POST",
    headers: {
      ...Http.HEADERS,
      Authorization: localStorage.getItem("token")
    },
    body: JSON.stringify({refreshToken})
  }
  return fetch(`${authApi}/v1/token/refresh`, configRefresh)
    .then(responseRefresh => responseRefresh.json())
}
