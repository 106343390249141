import React, { useEffect } from "react"
import { withRouter } from "react-router"
import { inject, observer } from "mobx-react"
import { Button } from "primereact/button"
import { Checkbox } from "primereact/checkbox"
import { Dropdown } from "primereact/dropdown"
import Preloader from "../../Preloader"

const UpdateMerchantUser = observer(
  ({
    userId,
    match,
    isLoading,
    userPermissions,
    templates,
    templatePermissions,
    isUpdated,
    getUser,
    getUserAndTemplates,
    selectedTemplateId,
    handlePermissionToogle,
    handleTemplateSelection,
    updateMerchantUser,
  }) => {
    const merchantId = Number(match.params.id)
    useEffect(() => {
      templates.length
        ? getUser(merchantId, userId)
        : getUserAndTemplates(merchantId, userId)
    }, [getUser, getUserAndTemplates, templates, merchantId, userId])

    const colStyle = "p-col-12 p-md-6"

    const checkboxList = [
      { value: "manageUsers", label: "Manage Users" },
      { value: "managePayment", label: "Add Payment Info" },
      { value: "uploadDocuments", label: "Upload Documents" },
    ]

    const renderCustomCheckBoxes = checkboxList.map((checkBox, i) => (
      <div key={i} className={colStyle}>
        <Checkbox
          name={checkBox.value}
          value={userPermissions[checkBox.value]}
          checked={userPermissions[checkBox.value]}
          onChange={({ target }) => handlePermissionToogle(target.name)}
        ></Checkbox>
        <label htmlFor={checkBox.value} className="p-checkbox-label">
          {checkBox.label}
        </label>
      </div>
    ))

    const selectTemplate = (
      <>
        <p className={`${colStyle} font-weight-bold`}>Use Template:</p>
        <div className={colStyle}>
          <Dropdown
            options={[
              { label: "Dont Use Template", value: null },
              ...templates.map((template) => ({
                label: template.name,
                value: template.id,
              })),
            ]}
            value={selectedTemplateId}
            onChange={(event) => {
              handleTemplateSelection(event.value)
            }}
          ></Dropdown>
        </div>
      </>
    )

    const renderTemplateCheckBoxes = checkboxList.map((checkBox, i) => (
      <div key={i} className={colStyle}>
        <Checkbox
          name={checkBox.value}
          value={templatePermissions[checkBox.value]}
          checked={templatePermissions[checkBox.value]}
          disabled={true}
        ></Checkbox>
        <label htmlFor={checkBox.value} className="p-checkbox-label">
          {checkBox.label}
        </label>
      </div>
    ))

    if (isLoading) {
      return Preloader("Loading...")
    }
    return (
      <div className="p-grid">
        {selectTemplate}
        {selectedTemplateId ? renderTemplateCheckBoxes : renderCustomCheckBoxes}
        <Button
          type="submit"
          label="Update User"
          onClick={() => updateMerchantUser(merchantId, userId)}
          disabled={!isUpdated}
        />
      </div>
    )
  }
)

const withData = inject((stores) => ({
  merchantId: stores.MerchantsStore.selectedMerchantId,
  isLoading: stores.UpdateMerchantUserStore.isLoading,
  templates: stores.UpdateMerchantUserStore.templates,
  userPermissions: stores.UpdateMerchantUserStore.userPermissions,
  templatePermissions: stores.UpdateMerchantUserStore.templatePermissions,
  isUpdated: stores.UpdateMerchantUserStore.isUpdated,
  getUser: stores.UpdateMerchantUserStore.getUser,
  getUserAndTemplates: stores.UpdateMerchantUserStore.getUserAndTemplates,
  handlePermissionToogle: stores.UpdateMerchantUserStore.handlePermissionToogle,
  selectedTemplateId: stores.UpdateMerchantUserStore.selectedTemplateId,
  handleTemplateSelection:
    stores.UpdateMerchantUserStore.handleTemplateSelection,
  updateMerchantUser: stores.UpdateMerchantUserStore.updateMerchantUser,
}))

export default withData(withRouter(UpdateMerchantUser))
