import React, { useEffect, useState } from "react"
import { Redirect, Route } from "react-router-dom"
import DrawerMenu from "./components/DrawerMenu"
import { Helmet } from "react-helmet"
import { inject } from "mobx-react"

const PrivateRoute = ({
  component: Component,
  isLogin,
  isLoading,
  currentAuthenticatedUser,
  title,
  user,
  ...rest
}) => {
  const [auth, setAuth] = useState(false)
  const [isTokenValidated, setIsTokenValidated] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer)
  }

  useEffect(() => {
    if (!isLogin && !isTokenValidated) {
      currentAuthenticatedUser()
    }
    setAuth(true)
    setIsTokenValidated(true)
  }, [isLogin, isTokenValidated, currentAuthenticatedUser])

  if (!isTokenValidated || (isLoading && !isLogin)) return <div />

  return (
    <Route
      {...rest}
      component={(props) =>
        auth && isLogin ? (
          <>
            <Helmet>
              <title>{title}</title>
            </Helmet>
            <DrawerMenu
              status={showDrawer}
              onClose={toggleDrawer}
              user={user}
            />
            <main className={`content-with-nav ${showDrawer && `open`}`}>
              <h1>{title}</h1>
              <Component {...props} />
            </main>
          </>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  )
}

const withData = inject((stores) => ({
  isLoading: stores.AuthStore.isLoading,
  isLogin: stores.AuthStore.isLogin,
  currentAuthenticatedUser: stores.AuthStore.currentAuthenticatedUser,
  user: stores.AuthStore.user,
}))

export default withData(PrivateRoute)
