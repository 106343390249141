import React, { useRef } from "react"
import { inject, observer } from "mobx-react"
import { Field, Form, Formik } from "formik"
import * as yup from "yup"
import { Button } from "primereact/button"
import { Message } from "primereact/message"
import CustomInput from "../../../components/common/CustomInput"
import CustomGroupInput from "../../../components/common/CustomGroupInput"
import { ErrorMsg } from "../../../constants/errorMsg"
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH, PASSWORD_REGEXP } from "../../../constants/user"

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email(ErrorMsg.EMAIL_NOT_VALID)
    .required(ErrorMsg.EMAIL_REQUIRED),
  password: yup
    .string()
    .trim()
    .required(ErrorMsg.PASSWORD_REQUIRED)
    .min(MIN_PASSWORD_LENGTH, ErrorMsg.PASSWORD_NOT_VALID)
    .max(MAX_PASSWORD_LENGTH, ErrorMsg.PASSWORD_NOT_VALID)
    .matches(PASSWORD_REGEXP, ErrorMsg.PASSWORD_NOT_VALID),
})

const FormLogin = observer(({ isLoading, isError, errorMsg, tryAuth, enabled2fa, enabledSms, generateSms }) => {
  const formRef = useRef()

  const resendCode = () => {
    if (formRef.current) {
      formRef.current.validateForm().then((values) => {
        if (Object.keys(values).length === 0) {
          generateSms(formRef.current.values)
        }
      })
    }
  }

  return (
    <Formik
      initialValues={{
        email: "", password: "", twoFactorAuthenticationCode: ""
      }}
      validationSchema={schema}
      onSubmit={tryAuth}
      innerRef={formRef}
    >
      {({ dirty }) => (
        <Form>
          <div className="p-grid">
            {isError && errorMsg && (
              <div className="p-col-12">
                <Message severity="error" text={errorMsg}/>
              </div>
            )}
            <div className="p-col-12">
              <Field
                type="text"
                name="email"
                labelText="Email"
                component={CustomInput}
              />
            </div>
            <div className="p-col-12">
              <Field
                type="password"
                name="password"
                labelText="Password"
                component={CustomInput}
              />
            </div>
            {enabled2fa && (
              <div className="p-col-12">
                <Field
                  type="text"
                  name="twoFactorAuthenticationCode"
                  labelText="Password from Google Authenticator app"
                  component={CustomInput}
                />
              </div>
            )}
            {enabledSms && (
              <div className="p-col-12">
                <Field
                  type="text"
                  name="twoFactorAuthenticationCode"
                  labelText="Code from SMS"
                  buttonText="Resend&nbsp;code"
                  handleButton={resendCode}
                  component={CustomGroupInput}
                />
              </div>
            )}
            <div className="p-col-12">
              <Button
                type="submit"
                label="Login"
                disabled={!(dirty && !isLoading)}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
})

const withData = inject((stores) => ({
  isLoading: stores.AuthStore.isLoading,
  isError: stores.AuthStore.isError,
  errorMsg: stores.AuthStore.errorMsg,
  tryAuth: stores.AuthStore.tryAuth,
  enabled2fa: stores.AuthStore.enabled2fa,
  enabledSms: stores.AuthStore.enabledSms,
  generateSms: stores.AuthStore.generateSms,
}))

export default withData(FormLogin)
