import React from "react"
import { inject, observer } from "mobx-react"
import { Field, Form, Formik } from "formik"
import * as yup from "yup"
import { Button } from "primereact/button"
import { Message } from "primereact/message"
import CustomInput from "../../../components/common/CustomInput"
import { ErrorMsg } from "../../../constants/errorMsg"
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH, PASSWORD_REGEXP } from "../../../constants/user"

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email(ErrorMsg.EMAIL_NOT_VALID)
    .required(ErrorMsg.EMAIL_REQUIRED),
  password: yup
    .string()
    .trim()
    .required(ErrorMsg.PASSWORD_REQUIRED)
    .min(MIN_PASSWORD_LENGTH, ErrorMsg.PASSWORD_NOT_VALID)
    .max(MAX_PASSWORD_LENGTH, ErrorMsg.PASSWORD_NOT_VALID)
    .matches(PASSWORD_REGEXP, ErrorMsg.PASSWORD_NOT_VALID),
  repeatPassword: yup
    .string()
    .required(ErrorMsg.CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("password")], ErrorMsg.PASSWORD_NOT_MATCH),
})

const FormRegistration = observer(({ isLoading, isError, errorMsg, createAccount }) => {
  return (
    <Formik
      initialValues={{
        email: "", password: "", repeatPassword: "",
      }}
      validationSchema={schema}
      onSubmit={createAccount}
    >
      {({ dirty }) => (
        <Form>
          <div className="p-grid">
            {isError && errorMsg && (
              <div className="p-col-12">
                <Message severity="error" text={errorMsg}/>
              </div>
            )}
            <div className="p-col-12">
              <Field
                type="text"
                name="email"
                labelText="Email"
                component={CustomInput}
              />
            </div>
            <div className="p-col-12">
              <Field
                type="password"
                name="password"
                labelText="Password"
                component={CustomInput}
              />
            </div>
            <div className="p-col-12">
              <Field
                type="password"
                name="repeatPassword"
                labelText="Repeat password"
                component={CustomInput}
              />
            </div>
            <div className="p-col-12">
              <Button
                type="submit"
                label="Create account"
                disabled={!(dirty && !isLoading)}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
})

const withData = inject((stores) => ({
  isLoading: stores.AuthStore.isLoading,
  isError: stores.AuthStore.isError,
  errorMsg: stores.AuthStore.errorMsg,
  createAccount: stores.AuthStore.createAccount,
}))

export default withData(FormRegistration)
