import React, { useEffect, useState } from "react"
import { inject, observer } from "mobx-react"
import { Redirect } from "react-router-dom"
import classNames from "classnames"

import WalletsBalance from "./WalletsBalance"
import WalletsRemittance from "./WalletsRemittance"
import { Dialog } from "primereact/dialog"

import "./WalletsGrid.scss"

const WalletsGrid = observer(
  ({
    isMerchant,
    isLoading,
    getWallets,
    deleteWallet,
    walletsData,
    currencyList,
  }) => {
    const [remittancePopup, setRemittancePopup] = useState(false)
    const [balancePopup, setBalancePopup] = useState(null)
    const [activeCurrency, setActiveCurrency] = useState("")
    const [activeCommission, setActiveCommission] = useState(0)

    const [walletFrom, setWalletFrom] = useState("")
    const [redirect, setRedirect] = useState(false)

    useEffect(() => {
      getWallets(isMerchant)
    }, [getWallets, isMerchant])

    const parseBalance = (sum) => (sum / 100).toFixed(2)

    let walletsGridBlock = null
    if (walletsData.length !== 0 && currencyList.length !== 0) {
      walletsGridBlock = walletsData.map((item) => {
        let currency = []
        currency = currencyList.filter(
          (currency) => currency.id === item.currencyCode
        )

        const currencyCodeSymbol =
          currency.length !== 0 ? currency[0].codeSymbol : ""
        const currencyCodeLetter =
          currency.length !== 0 ? currency[0].codeLetter : "Not found"
        const currencyCommission =
          currency.length !== 0 ? currency[0].coefficient : 0

        const remittanceButton =
          item.amountMoney > 0 ? (
            <button
              className="wallet-grid__button"
              onClick={() => {
                setRemittancePopup(item.amountMoney)
                setWalletFrom(item.id)
                setActiveCommission(currencyCommission)
              }}
            >
              remittance
            </button>
          ) : null

        const menu = item.blockedStatus ? (
          <span>Wallet is locked</span>
        ) : (
          <>
            <button
              className="wallet-grid__button"
              onClick={() => {
                setBalancePopup(item.id)
                setActiveCurrency(currencyCodeLetter)
              }}
            >
              balance
            </button>
            {remittanceButton}
            <button
              className="wallet-grid__button"
              onClick={() => deleteWallet(item.id, isMerchant)}
            >
              delete
            </button>
          </>
        )

        return (
          <div key={item.id} className="p-col-12 p-lg-4">
            <div
              className={classNames("wallet-grid", {
                blocked: item.blockedStatus,
              })}
            >
              <label>{currencyCodeLetter}</label>
              <div className="wallet-grid__currencies">
                {currencyCodeSymbol}
              </div>
              <div
                className="wallet-grid__balance"
                onClick={() => setRedirect(`/wallets/${item.id}${isMerchant ? `?merchant=${isMerchant}` : ''}`)}
              >
                <span style={{ cursor: "pointer" }}>{item.name}</span>
              </div>
              <div className="wallet-grid__balance">{item.wNumber}</div>
              <div className="wallet-grid__balance">
                <span className="wallet-grid__balance-currency">
                  {currencyCodeSymbol}
                </span>
                <span className="wallet-grid__balance-count">
                  {parseBalance(item.amountMoney)}
                </span>
              </div>
              <div className="wallet-grid__menu">{menu}</div>
            </div>
          </div>
        )
      })
    }

    const popupRemittance = remittancePopup ? (
      <Dialog
        header="Money transfer"
        visible={!!remittancePopup}
        className="popup-width"
        modal={true}
        onHide={() => setRemittancePopup(false)}
        baseZIndex={9999}
      >
        <WalletsRemittance
          wallet={walletFrom}
          commission={activeCommission}
          currency={activeCurrency}
          isMerchant={isMerchant}
          close={() => {
            setWalletFrom("")
            setRemittancePopup(false)
          }}
        />
      </Dialog>
    ) : null

    const popupBalance = !!balancePopup ? (
      <WalletsBalance
        wallet={balancePopup}
        currency={activeCurrency}
        isMerchant={isMerchant}
        isOpen={!!balancePopup}
        closeAction={() => {
          getWallets(isMerchant)
          setBalancePopup(false)
        }}
      />
    ) : null

    return (
      <>
        {redirect && <Redirect to={redirect} />}
        {isLoading ? (
          <div className="wallet-grid__load">
            <i className="pi pi-spin pi-spinner" style={{ fontSize: "5em" }} />
          </div>
        ) : (
          walletsGridBlock
        )}
        {popupRemittance}
        {popupBalance}
      </>
    )
  }
)

const withData = inject((store) => ({
  isLoading: store.WalletsStore.isLoading,
  isLoadingCurrency: store.WalletsStore.isLoadingCurrency,
  getWallets: store.WalletsStore.getWallets,
  getCurrency: store.WalletsStore.getCurrency,
  deleteWallet: store.WalletsStore.deleteWallet,
  walletsData: store.WalletsStore.walletsData,
  currencyList: store.WalletsStore.currencyList,
}))

export default withData(WalletsGrid)
