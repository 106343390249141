import { observable, action } from "mobx"
import Http from "../../helpers/http"
import { authApi } from "../../configs/api"

class ProductStore {
  @observable isLoading = false
  @observable isError = false
  @observable errorMsg = null

  @action
  payWayForPay = async (wallet, amount, recToken, comment) => {
    try {
      const response = await Http.post(
        `${authApi}/v1/wayforpay/charge`,
        { wallet, amount, recToken, comment },
        true
      )
      const { status } = response
      if (status === "Success") {
        this.operationComplite(true)
        this.getWallets()
        setTimeout(() => this.operationComplite(false), 1000)
      }
    } catch (e) {
      this.isError = true
      this.errorMsg = e
    }
  }
}

export default new ProductStore()
