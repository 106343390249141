import React, { useEffect, useRef, useState } from "react"
import { withRouter } from "react-router"
import { inject, observer } from "mobx-react"
import { Field, Form, Formik } from "formik"
import { InputTextarea } from "primereact/inputtextarea"
import { Message } from "primereact/message"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { FileUpload } from "primereact/fileupload"
import CustomSelect from "../../common/CustomSelect"

import * as yup from "yup"

import CustomInput from "../../common/CustomInput"
import Preloader from "../../Preloader"
import { ErrorMsg } from "../../../constants/errorMsg"
import {
  PRODUCT_NAME_MIN_LENGTH,
  PRODUCT_NAME_MAX_LENGTH,
  PRODUCT_DESCRIPTION_MIN_LENGTH,
  PRODUCT_DESCRIPTION_MAX_LENGTH,
  PRICE_REGEXP,
} from "../../../constants/merchant"
import { getMessage } from "../../../services/getMessage"

const AddPaymentInfo = observer(
  ({
    match,
    productId,
    product,
    isLoading,
    isLoadingWidget,
    isRequestingPublishing,
    image,
    imageChanged,
    productWidget,
    availableCurrencies,
    setImage,
    clearImage,
    updatingProduct,
    getProduct,
    updateProduct,
    requestPublishing,
    getWidget,
    clearProductWidget,
  }) => {
    const merchantId = Number(match.params.id)
    useEffect(() => {
      getProduct(productId)
      return clearImage
    }, [getProduct, productId, clearImage])
    let uploadRef = useRef(null)
    const [imageError, setImageError] = useState("")
    const [showModal, setShowModal] = useState(false)
    const schema = yup.object().shape({
      name: yup
        .string()
        .required(ErrorMsg.PRODUCT_NAME_REQUIRED)
        .min(PRODUCT_NAME_MIN_LENGTH, ErrorMsg.PRODUCT_NAME_NOT_VALID)
        .max(PRODUCT_NAME_MAX_LENGTH, ErrorMsg.PRODUCT_NAME_NOT_VALID),
      description: yup
        .string()
        .required(ErrorMsg.PRODUCT_DESCRIPTION_REQUIRED)
        .min(
          PRODUCT_DESCRIPTION_MIN_LENGTH,
          ErrorMsg.PRODUCT_DESCRIPTION_NOT_VALID
        )
        .max(
          PRODUCT_DESCRIPTION_MAX_LENGTH,
          ErrorMsg.PRODUCT_DESCRIPTION_NOT_VALID
        ),
      price: yup
        .string()
        .required(ErrorMsg.PRODUCT_PRICE_REQUIRED)
        .matches(PRICE_REGEXP, ErrorMsg.PRODUCT_PRICE_NOT_VALID),
      currencyId: yup
        .number()
        .positive(ErrorMsg.CURRENCY_ID_REQUIRED)
        .required(ErrorMsg.CURRENCY_ID_REQUIRED)
        .typeError(ErrorMsg.CURRENCY_ID_REQUIRED),
    })

    const productTypes = ["Service", "Physical product", "E-product"]

    const TextArea = ({ form, field }) => {
      const showError = Boolean(
        form.touched[field.name] && form.errors[field.name]
      )

      return (
        <>
          <div className="p-float-label">
            <InputTextarea
              id="float-textarea"
              rows={4}
              style={{ width: "100%" }}
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              autoResize={true}
            />
            <label htmlFor="float-textarea">Describe your product here</label>
          </div>
          {showError && (
            <Message
              severity="error"
              text={getMessage(form.errors[field.name])}
            />
          )}
        </>
      )
    }

    const renderImage = image ? (
      <div className="p-col-12 ">
        <img style={{ maxWidth: "15%" }} src={image.objectURL} alt="product" />
      </div>
    ) : null

    const dialogContent = (
      <div className="p-grid p-dir-col p-align-center p-justify-center">
        {isLoadingWidget ? (
          <>{Preloader("Loading widget...")}</>
        ) : (
          <>
            <b>Copy Html code bellow to your site</b>
            <p style={{ whiteSpace: "pre" }}>{productWidget}</p>
          </>
        )}
      </div>
    )

    const renderFileUpload = (
      <FileUpload
        ref={uploadRef}
        name="demo"
        mode="basic"
        accept=".gif, .jpg, .png"
        customUpload={true}
        chooseLabel={image ? "Change picture" : "Select picture"}
        auto={true}
        uploadHandler={({ files }) => {
          const regExpFileType = new RegExp(/^.*\.(jpe?g|png|gif)$/i)
          uploadRef.current.clear()
          setImageError("")
          if (files[0].size > 5242880) {
            setImageError("Maximum upload size is 5mb")
            return
          }
          if (!regExpFileType.test(files[0].name)) {
            setImageError("Only *.jpeg, *.png and *.gif files supported")
            return
          }
          setImage(files[0])
        }}
      />
    )
    const approveStatuses = [
      "Request publish",
      "Publish requested",
      "Product Published",
      "Publish rejected",
    ]
    const renderApproveButton = (
      <Button
        label={approveStatuses[product.approved]}
        type="button"
        className=""
        disabled={product.approved !== 0 || isRequestingPublishing}
        onClick={() => {
          requestPublishing(productId, merchantId)
        }}
      />
    )
    const renderWidgetButton = (
      <Button
        label={"Get payment widget"}
        className=""
        type="button"
        onClick={(e) => {
          e.stopPropagation()
          e.nativeEvent.stopImmediatePropagation()
          getWidget(productId)
          setShowModal(true)
        }}
      />
    )

    const renderClearButton = image ? (
      <Button
        label="Clear Image"
        icon="pi pi-times"
        iconPos="left"
        className="p-button button-auto-width"
        onClick={(e) => {
          e.stopPropagation()
          e.nativeEvent.stopImmediatePropagation()
          clearImage()
        }}
      />
    ) : null

    if (isLoading) {
      return Preloader("Loading product...")
    }
    if (updatingProduct) {
      return Preloader("Updating product...")
    }
    return (
      <Formik
        initialValues={{
          name: product.name,
          price: product.price,
          description: product.description,
          currencyId: product.currencyId,
        }}
        enableReinitialize={true}
        validationSchema={schema}
        onSubmit={(values) => {
          values.price = Math.trunc(values.price * 100)
          updateProduct(values, merchantId)
        }}
      >
        {({ dirty, isValid }) => (
          <Form>
            <Dialog
              className="my-10"
              baseZIndex={1100}
              header={"Payment widget"}
              visible={showModal}
              closable={!isLoadingWidget}
              style={{ width: "80vw" }}
              modal={true}
              onHide={() => setShowModal(false)}
            >
              {dialogContent}
            </Dialog>
            <div className="p-grid pt-3">
              <div className="p-col-12 ">
                <Field
                  type="text"
                  name="name"
                  labelText="Product name"
                  component={CustomInput}
                  className="p-col-12"
                />
              </div>
              <div className="p-col-12">
                <Field
                  type="text"
                  name="description"
                  labelText="Product description"
                  component={TextArea}
                />
              </div>
              <div className="p-col-12">
                <Field
                  step="0.01"
                  type="number"
                  name="price"
                  labelText="Product price"
                  component={CustomInput}
                />
              </div>
              <div className="p-col-6 p-grid p-dir-col px-3">
                <Field
                  type="text"
                  className="p-col-12 py-0 mb-2"
                  name={"currencyId"}
                  options={availableCurrencies.map(({ name, id }) => ({
                    label: name,
                    value: id,
                  }))}
                  labelText="Select a Currency"
                  component={CustomSelect}
                />
              </div>
              <div className="p-col-6 p-grid p-col-align-start mt-2 ml-2">
                <b>Product type:</b>
                {productTypes[product.productType]}
              </div>
              {renderImage}
              <div className="p-col-6 p-grid p-align-start px-3">
                {renderFileUpload}
                {renderClearButton}
              </div>

              {imageError ? (
                <>
                  <div class="p-col-12 px-0 py-0" />
                  <div
                    aria-live="polite"
                    class="p-col-6 p-message p-component p-message-error text-center mb-3 mt-2 mx-2"
                    role="alert"
                  >
                    <span class="p-message-text">{imageError}</span>
                  </div>
                </>
              ) : (
                <p class="p-col-12 mt-0 mb-3">
                  Only *.jpeg, *.png and *.gif files will be accepted,size
                  should be no more than 5mb.
                </p>
              )}
            </div>
            <div className="p-grid">
              <div className="p-col-12">
                <Button
                  type="submit"
                  label="Update product"
                  disabled={!isValid || (!imageChanged && !dirty)}
                  className="p-col-12"
                />
              </div>
              <div className="p-col-12">
                {renderApproveButton}
              </div>
              <div className="p-col-12">
                {renderWidgetButton}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    )
  }
)

const withData = inject((stores) => ({
  merchantId: stores.MerchantsStore.selectedMerchantId,
  product: stores.MerchantUpdateProductStore.product,
  isLoading: stores.MerchantUpdateProductStore.isLoading,
  isLoadingWidget: stores.MerchantUpdateProductStore.isLoadingWidget,
  isRequestingPublishing:
    stores.MerchantUpdateProductStore.isRequestingPublishing,
  image: stores.MerchantUpdateProductStore.image,
  productWidget: stores.MerchantUpdateProductStore.productWidget,
  imageChanged: stores.MerchantUpdateProductStore.imageChanged,
  availableCurrencies: stores.MerchantProductsStore.availableCurrencies,
  setImage: stores.MerchantUpdateProductStore.setImage,
  clearImage: stores.MerchantUpdateProductStore.clearImage,
  clearProductWidget: stores.MerchantUpdateProductStore.clearProductWidget,
  updatingProduct: stores.MerchantUpdateProductStore.updatingProduct,
  getProduct: stores.MerchantUpdateProductStore.getProduct,
  getWidget: stores.MerchantUpdateProductStore.getWidget,
  requestPublishing: stores.MerchantUpdateProductStore.requestPublishing,
  updateProduct: stores.MerchantUpdateProductStore.updateProduct,
}))

export default withData(withRouter(AddPaymentInfo))
