import { observable, action,computed } from "mobx"
import Http from "../../../helpers/http"
import { authApi} from "../../../configs/api"

class UpdateMerchantUserStore {
  @observable isError = false
  @observable errorMsg = false
  @observable isLoading = true
  @observable user = {}
  @observable selectedTemplateId = null
  @observable templates = []
  @observable templatePermissions = {}
  @observable userPermissions = {}

  @computed get isUpdated() {
    if (this.user.MerchantUserTemplateId||this.selectedTemplateId) {
      return this.user.MerchantUserTemplateId !== this.selectedTemplateId
    }
    return Object.keys(this.userPermissions).reduce((isUpdated, permission) => {
      if (this.userPermissions[permission] !== this.user.permissions[permission]) {
        return true
      }
      return isUpdated
    }, false)

  }

  @action handlePermissionToogle = (permission) => {
    this.userPermissions = {...this.userPermissions,[permission]:!this.userPermissions[permission]}
  }

  @action handleTemplateSelection = (id) => {
    this.selectedTemplateId = id
    this.templatePermissions = id ? this.templates.find(template=>template.id===id)["permissions"] : {}
  }

  @action getUser = async (merchantId, mUserId) => {
    try {
      this.errorMsg = false
      this.isError = false
      this.isLoading = true
      const response = await Http.post(`${authApi}/v1/merchant/user/get`, { mUserId, merchantId }, true)
      this.isLoading = false
      if (!response.message) {
        this.user = response
        if(this.user.permissions){
          this.userPermissions = this.user.permissions
        }else{
          this.user.permissions={}
        }
        this.handleTemplateSelection(this.user.MerchantUserTemplateId)
      } else {
        this.isError = true
        this.errorMsg = response.message
      }
    } catch (e) {
      this.isLoading = false
      this.isError = true
      this.errorMsg = e
    }
  }

  @action getUserAndTemplates = async (merchantId, mUserId) => {
    try {
      this.errorMsg = false
      this.isError = false
      this.isLoading = true
      const response = await Promise.all(
        [
          Http.post(`${authApi}/v1/merchant/user/get`, { mUserId, merchantId }, true),
          Http.get(`${authApi}/v1/merchant/user-template/get-templates`, true)
        ])
      this.isLoading = false
      if (!response.message) {
        this.user = response[0]
        this.templates = response[1]['rows']
        if(this.user.permissions){
          this.userPermissions = this.user.permissions
        }else{
          this.user.permissions={}
        }
        this.handleTemplateSelection(this.user.MerchantUserTemplateId)
      } else {
        this.isError = true
        this.errorMsg = response.message
      }
    } catch (e) {
      this.isLoading = false
      this.isError = true
      this.errorMsg = e
    }
  }

  @action updateMerchantUser = async (merchantId,mUserId) => {
    try {
      const reqBody = this.selectedTemplateId ? {
        id:mUserId,
        merchantId,
        templateId: this.selectedTemplateId
      } : {
        id:mUserId,
        merchantId,
          permissions: {
            ...this.userPermissions
          }
        }
      this.errorMsg = false
      this.isError = false
      this.isLoading = true
      const response = await Http.post(`${authApi}/v1/merchant/user/update`, reqBody, true)
      this.isLoading = false
      if (!response.message) {
        this.user = response
        if(this.user.permissions){
          this.userPermissions = this.user.permissions
        }else{
          this.user.permissions={}
        }
        this.handleTemplateSelection(this.user.MerchantUserTemplateId)
      } else {
        this.isError = true
        this.errorMsg = response.message
      }
    } catch (e) {
      this.isLoading = false
      this.isError = true
      this.errorMsg = e
    }
  }



}

export default new UpdateMerchantUserStore()