import React, { useState, useCallback, useRef } from "react"
import { withRouter } from "react-router"
import { inject, observer } from "mobx-react"
import { useDropzone } from "react-dropzone"
import { Panel } from "primereact/panel"
import { Button } from "primereact/button"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import Preloader from "../../Preloader"
import moment from "moment"
import "./DocsUpload.scss"

const MerchantInDetails = observer(
  ({
    match,
    merchant,
    isLoading,
    isUploading,
    uploadDocs,
    fileErrorMsg,
    errorMsg,
  }) => {
    const [panelCollapsed, setPanelCollapsed] = useState(true)
    const [parsingDocuments, setParsingStatus] = useState(false)
    const [filesArr, setFilesArr] = useState([])
    const [filesError, setFilesError] = useState("")

    const merchantId = Number(match.params.id)
    const readFileAsync = (file) => {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()

        reader.onload = () => {
          resolve(reader.result)
        }

        reader.onerror = reject

        reader.readAsArrayBuffer(file)
      })
    }

    const arrayBufferToBase64 = (buffer) => {
      let binary = ""
      let bytes = new Uint8Array(buffer)
      let len = bytes.byteLength
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
      }
      return window.btoa(binary)
    }

    const onDrop = useCallback(
      (acceptedFiles) => {
        setFilesError("")
        const errorMsg = ["These files were rejected due to size: "]
        const fliteredAcceptedFiles = acceptedFiles.filter((item) => {
          if (item.size > 5242880) {
            errorMsg.push("\n" + item.name)
            return false
          } else {
            return true
          }
        })
        if (errorMsg.length > 1) {
          setFilesError(errorMsg.join(""))
        }
        const newFilesSize = acceptedFiles.reduce(
          (acc, item) => acc + item.size,
          0
        )
        const currentFilesSize = filesArr.reduce(
          (acc, item) => acc + item.size,
          0
        )
        if (newFilesSize + currentFilesSize > 15728640) {
          setFilesError(
            "Maximum upload size exceeded. The total size should be no more than 15mb."
          )
          return
        }
        setFilesArr([...filesArr, ...fliteredAcceptedFiles])
      },
      [filesArr]
    )

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: "image/jpeg, image/png, application/pdf",
      onDrop,
    })

    const files = filesArr.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ))

    const upload = async () => {
      setParsingStatus(true)
      const newFileArr = []
      for (let i = 0; i < filesArr.length; i++) {
        const fileBuffer = await readFileAsync(filesArr[i])
        const fileBase64 = arrayBufferToBase64(fileBuffer)
        newFileArr.push({
          file: fileBase64,
          filename: filesArr[i].name,
        })
      }
      uploadDocs(merchantId, newFileArr)
      setParsingStatus(false)
      setFilesArr([])
    }

    const cols = [
      { field: "filename", header: "File name" },
      { field: "uploaded", header: "Upload date" },
      { field: "status", header: "Status" },
    ]
    const statuses = ["New", "Rejected", "Approved"]

    const documentsList =
      merchant && merchant.documents ? (
        <DataTable
          className="mt-3"
          value={merchant.documents.map(({ filename, status }) => ({
            filename: filename.substring(14),
            uploaded: moment(+filename.substring(0, 13)).format(
              "DD/MM/YYYY HH:mm:ss"
            ),
            status: statuses[status],
          }))}
          dataKey="fileName"
        >
          {cols.map((col) => (
            <Column key={col.field} field={col.field} header={col.header} />
          ))}
        </DataTable>
      ) : (
        "Documents not found"
      )

    const uploadDocuments = (
      <div className="p-col-12">
        <div className="p-grid">
          {filesError ? (
            <div className="p-col-12">
              <div
                aria-live="polite"
                class="p-col-12 p-message p-component p-message-error text-center"
                role="alert"
              >
                <pre>
                  <span class="p-message-text">{filesError}</span>
                </pre>
              </div>
            </div>
          ) : null}
          <div className="p-col-12">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <>
                  <p>Drop the files here ...</p>
                  <p> </p>
                  <p> </p>
                </>
              ) : (
                <>
                  <p>Drag 'n' drop some files here, or click to select files</p>
                  <em>(Only *.jpeg, *.png and *.pdf files will be accepted)</em>
                  <em>
                    (Each file must be no more than 5mb. The total size should
                    be no more than 15mb.)
                  </em>
                </>
              )}
            </div>
          </div>

          <div className="p-col-12">
            <ul>{files}</ul>
          </div>
          <div className="p-col-12">
            <Button
              icon="pi pi-upload"
              type="submit"
              label="Upload documents"
              disabled={isLoading}
              onClick={() => {
                setFilesError("")
                upload()
              }}
            />
          </div>
        </div>
      </div>
    )

    const panel = (
      <Panel
        className="mt-3"
        header="Documents"
        toggleable={true}
        collapsed={panelCollapsed}
        onToggle={(e) => setPanelCollapsed(e.value)}
      >
        <div className="p-grid">
          {isLoading || isUploading || parsingDocuments ? (
            Preloader(
              isLoading
                ? "Loading documents..."
                : isUploading
                ? "Uploading documents..."
                : "Prepearing documents"
            )
          ) : (
            <>
              {documentsList}
              {uploadDocuments}
            </>
          )}
        </div>
      </Panel>
    )

    return <>{panel}</>
  }
)

const withData = inject((stores) => ({
  merchantId: stores.MerchantsStore.selectedMerchantId,
  isLoading: stores.MerchantsStore.isLoading,
  merchant: stores.MerchantInfoStore.merchant,
  isUploading: stores.MerchantsDocumentsStore.isUploading,
  uploadDocs: stores.MerchantsDocumentsStore.uploadDocs,
  fileErrorMsg: stores.MerchantsDocumentsStore.fileErrorMsg,
  errorMsg: stores.MerchantsDocumentsStore.errorMsg,
}))

export default withData(withRouter(MerchantInDetails))
