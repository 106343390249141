import React, { useEffect, useState } from "react"
import { inject, observer } from "mobx-react"
import { Link, Redirect } from "react-router-dom"
import { Card } from "primereact/card"
import { Button } from "primereact/button"
import FormLogin from "./components/FormLogin"
import FormRegistration from "./components/FormRegistration"

import "./LoginPage.scss"

const LoginPage = observer((props) => {
  const { isLogin, user, clearErrors } = props
  const [isRegistration, setIsRegistration] = useState(false)

  useEffect(() => {
    return () => {
      clearErrors()
    }
  }, [clearErrors])

  if (isLogin && user) {
    return <Redirect to="/dashboard"/>
  }

  return (
    <div className="p-fluid">
      <div className="p-grid p-justify-center">
        <div className="p-col-12 p-md-6 p-lg-4 mt-5">
          {!isRegistration ? (
            <Card title="Login">
              <div className="p-grid">
                <div className="p-col-12">
                  <FormLogin/>
                </div>
                <div className="p-col-12">
                  <Button
                    type="button"
                    label="Registration"
                    onClick={() => setIsRegistration(true)}
                    className="p-button-secondary"
                  />
                </div>
              </div>
            </Card>
          ) : (
            <Card title="Registration">
              <div className="p-grid">
                <div className="p-col-12">
                  <FormRegistration/>
                </div>
                <div className="p-col-12">
                  <Button
                    type="button"
                    label="Login"
                    onClick={() => setIsRegistration(false)}
                    className="p-button-secondary"
                  />
                </div>
              </div>
            </Card>
          )}
          <div className="p-col-12 text-center">
            <Link
              to="/forgot-password"
            >
              Forgot password?
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
})

const withData = inject((stores) => ({
  isError: stores.AuthStore.isError,
  errorMsg: stores.AuthStore.errorMsg,
  isLoading: stores.AuthStore.isLoading,
  isLogin: stores.AuthStore.isLogin,
  user: stores.AuthStore.user,
  clearErrors: stores.AuthStore.clearErrors,
}))

export default withData(LoginPage)
