import React, { useState } from "react"
import { observer } from "mobx-react"
import { TabPanel, TabView } from "primereact/tabview"
import WayForPayCards from "../../components/WayForPay"
import StripeCards from "../../components/Stripe/StripeCards"

import "./CreditCards.scss"

const CreditCardsPage = observer(() => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
      <TabPanel header="WayForPay">
        <WayForPayCards/>
      </TabPanel>
      <TabPanel header="LiqPay">
        See soon
      </TabPanel>
      <TabPanel header="Stripe">
        <StripeCards/>
      </TabPanel>
    </TabView>
  )
})

export default CreditCardsPage
