import { observable, action } from "mobx"
import Http from "../../helpers/http"
import { authApi } from "../../configs/api"

class LiqPayStore {
  @observable isLoading = false
  @observable isError = false
  @observable errorMsg = null
  @observable liqpayPaymentForm = ""
  @observable cardForm = ""

  @action
  liqpayPayment = async (amount, wallet, comment) => {
    this.liqpayPaymentForm = ""
    try {
      const response = await Http.post(
        `
        ${authApi}/v1/liqpay/payment`,
        { amount, wallet, comment },
        true
      )
      if (response.formCode) {
        this.liqpayPaymentForm = response.formCode.replace("\\", "")
      }
    } catch (e) {
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  getCardForm = async (wallet, currency, amount) => {
    try {
      const response = await Http.get(
        `${authApi}/v1/liqpay/payment?wallet=${wallet}&amount=${amount}&currency=${currency}`,
        true
      )

      this.cardForm = response.responseHtml
    } catch (e) {
      this.isError = true
      this.errorMsg = e
    }
  }
}

export default new LiqPayStore()
