import React from "react"
import { Link } from "react-router-dom"
import { inject, observer } from "mobx-react"
import { Field, Form, Formik } from "formik"
import * as yup from "yup"
import { Card } from "primereact/card"
import { Button } from "primereact/button"
import { Message } from "primereact/message"
import CustomInput from "../../components/common/CustomInput"
import { ErrorMsg } from "../../constants/errorMsg"

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email(ErrorMsg.EMAIL_NOT_VALID)
    .required(ErrorMsg.EMAIL_REQUIRED),
})

const ForgotPassword = observer(({ isLoading, isError, errorMsg, forgotPassword }) => (
  <div className="p-fluid">
    <div className="p-grid p-justify-center">
      <div className="p-col-12 p-md-6 p-lg-4 mt-5">
        <Card title="Forgot password">
          <Formik
            initialValues={{
              email: "",
            }}
            enableReinitialize
            validationSchema={schema}
            onSubmit={async (values, { resetForm }) => {
              await forgotPassword(values)
              resetForm({})
            }}
          >
            {({ dirty }) => (
              <Form>
                <div className="p-grid">
                  {isError && errorMsg && (
                    <div className="p-col-12">
                      <Message severity="error" text={errorMsg}/>
                    </div>
                  )}
                  <div className="p-col-12">
                    <Field
                      name="email"
                      labelText="Email"
                      component={CustomInput}
                    />
                  </div>
                  <div className="p-col-12">
                    <Button
                      type="submit"
                      label="Send"
                      disabled={!(dirty && !isLoading)}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <div className="p-grid">
            <div className="p-col-12 text-center">
              <Link to="/">
                <Button
                  type="button"
                  label="Login"
                  className="p-button-secondary"
                />
              </Link>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
))

const withData = inject((stores) => ({
  isLoading: stores.AuthStore.isLoading,
  isError: stores.AuthStore.isError,
  errorMsg: stores.AuthStore.errorMsg,
  forgotPassword: stores.AuthStore.forgotPassword,
}))

export default withData(ForgotPassword)
