import React from "react"
import { observer } from "mobx-react"
import { Card } from "primereact/card"
import { Button } from "primereact/button"

import { authApi } from "../../../configs/api"
import noImage from "./No_image_available.png"
import "./ProductPlate.scss"

const ProductPlate = observer(({ product, currency }) => {
  const formattedPrice = (price) => {
    if ((price / 100) % 1) {
      return (price / 100).toString().padEnd(price.toString().length + 1, "0")
    }
    return `${price / 100}.00`
  }

  const { name, description, price, image } = product
  const currencyName = currency.name
  const imageUrl = image ? `${authApi}/v1/img/${image}` : noImage
  const header = (
    <div class="product-image product-image-contain">
      <img alt="Card" src={imageUrl} />
    </div>
  )
  const footer = (
    <span>
      <Button
        label="Buy"
        icon="pi pi-dollar"
        style={{ marginRight: ".25em" }}
      />
    </span>
  )
  return (
    <div className="p-col-12 p-lg-3">
      <Card
        style={{ overflowWrap: "break-word" }}
        title={name}
        subTitle={`${formattedPrice(price)} ${currencyName}`}
        footer={footer}
        header={header}
      >
        <div className="product-description">{description}</div>
      </Card>
    </div>
  )
})

export default ProductPlate
