import React, { useEffect, useRef } from "react"
import { inject, observer } from "mobx-react"
import moment from "moment"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import StripeAddCard from "./StripeAddCard/StripeAddCard"

const StripePayment = observer(({ getCreditCards, creditCardsList, deleteCard, isLoading }) => {
    const dt = useRef(null)

    useEffect(() => {
      getCreditCards()
    }, [getCreditCards])


    const formattedCardNumber = (rowData) => {
      return <>**** **** **** {rowData.card.last4}</>
    }

    const formattedDate = (rowData) => {
      return <>{moment.unix(rowData.created).format("DD/MM/YYYY HH:mm:ss")}</>
    }

    const actionBodyTemplate = (card) => (
      <>
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-secondary ml-2"
          onClick={() => deleteCard(card)}
        />
      </>
    )

    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12 p-md-8">
          <DataTable
            ref={dt}
            value={creditCardsList}
            responsive
            className="p-datatable-customers"
            dataKey="id"
            rowHover
            emptyMessage="No Stripe cards found"
            loading={isLoading}
          >
            <Column field="card.last4" header="Card number" body={formattedCardNumber}/>
            <Column field="card.exp_month" header="Exp. month"/>
            <Column field="card.exp_year" header="Exp. year"/>
            <Column field="created" header="Added at" body={formattedDate}/>
            <Column
              body={actionBodyTemplate}
              headerClassName="actionHeader"
              bodyClassName="actionBody"
            />
          </DataTable>
        </div>
        <div className="p-col-12 p-md-4">
          <StripeAddCard/>
        </div>
      </div>
    )
  }
)

const withData = inject((stores) => ({
  getCreditCards: stores.StripeStore.getCreditCards,
  deleteCard: stores.StripeStore.deleteCard,
  creditCardsList: stores.StripeStore.creditCardsList,
  isLoading: stores.StripeStore.isLoading,
}))

export default withData(StripePayment)
