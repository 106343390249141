import React, { useEffect, useRef, useState } from "react"
import { Redirect, useLocation } from "react-router-dom"
import { inject, observer } from "mobx-react"
import { Growl } from "primereact/growl"

import "./WalletInfoBlock.scss"

const WalletInfoPage = observer(
  ({ match, isLoading, isError, errorMsg, walletData, getWalletInfo }) => {
    const walletId = match.params.id
    const merchant = useLocation().search;

    const [redirect, setRedirect] = useState(false)
    let infoMessage = useRef(null)

    if (isError) {
      infoMessage.current.show([
        { severity: "error", detail: errorMsg, closable: false },
      ])
    }

    useEffect(() => {
      getWalletInfo(walletId, merchant)
    }, [getWalletInfo, walletId])

    const parseBalance = (sum) => (sum / 100).toFixed(2)

    const renderType = (data) => {
      if (data === 1) {
        return <>To wallet</>
      }
      if (data === 2) {
        return <>To card</>
      }
      return <></>
    }

    const walletInfo = (data) => (
      <div className="wallet-info">
        <div className="wallet-info__header">Wallet info</div>
        <div className="wallet-info__row">
          <div className="wallet-info__label">Wallet id:</div>
          <div className="wallet-info__value">{data.id}</div>
        </div>
        <div className="wallet-info__row">
          <div className="wallet-info__label">Wallet name:</div>
          <div className="wallet-info__value">{data.name}</div>
        </div>
        <div className="wallet-info__row">
          <div className="wallet-info__label">Code number:</div>
          <div className="wallet-info__value">{data.wNumber}</div>
        </div>
        <div className="wallet-info__row">
          <div className="wallet-info__label">Amount:</div>
          <div className="wallet-info__value">
            {parseBalance(data.amountMoney)}
          </div>
        </div>
        <div className="wallet-info__row">
          <div className="wallet-info__label">Hold:</div>
          <div className="wallet-info__value">
            {parseBalance(data.holdAmount)}
          </div>
        </div>
      </div>
    )

    const makeBlockInfo = (data, type) => {
      let rows = []
      if (type === "payment") {
        rows = data.map((item) => (
          <div className="wallet-info__row" key={item.id}>
            <div className="wallet-info__label">
              {renderType(item.transactionType)}
            </div>
            <div className="wallet-info__value">
              {parseBalance(item.amount)}
            </div>
          </div>
        ))
      } else {
        rows = data.map((item) => (
          <div className="wallet-info__row" key={item.id}>
            <div className="wallet-info__label">{item.transactionType}</div>
            <div className="wallet-info__value">
              {parseBalance(item.amount)}
            </div>
          </div>
        ))
      }
      return (
        <div className="wallet-info">
          <div className="wallet-info__header">
            {type === "payment" ? "Payment transaction" : "Inner transaction"}
          </div>
          {rows}
        </div>
      )
    }

    return (
      <>
        {redirect && <Redirect to={redirect} />}
        <div className="content-section">
          <div className="p-grid">
            <div className="p-col-12">
              <div
                className="wallet-info__back"
                onClick={() => {
                  const link = walletData.walletData?.merchantId ? `/merchant/${walletData.walletData?.merchantId}` : "/wallets"
                  setRedirect(link)
                }}
              >
                <i className="pi pi-angle-left" /> {walletData.walletData?.merchantId ? 'Merchant info' : 'All wallets'}
              </div>
            </div>
          </div>
          <div className="p-grid">
            <div className="p-col-4">
              {walletData.walletData && walletInfo(walletData.walletData)}
            </div>
            <div className="p-col-4">
              {walletData.innerTransaction &&
                makeBlockInfo(walletData.innerTransaction, "inner")}
            </div>
            <div className="p-col-4">
              {walletData.paymentTransaction &&
                makeBlockInfo(walletData.paymentTransaction, "payment")}
            </div>
          </div>
          <Growl ref={infoMessage} />
        </div>
      </>
    )
  }
)

const withData = inject((store) => ({
  isLoading: store.WalletInfoStore.isLoading,
  isError: store.WalletInfoStore.isError,
  errorMsg: store.WalletInfoStore.errorMsg,
  walletData: store.WalletInfoStore.walletData,
  getWalletInfo: store.WalletInfoStore.getWalletInfo,
}))

export default withData(WalletInfoPage)
